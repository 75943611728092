import { useEffect, useState } from "react";
import React from 'react'

const CircleProgress = ({ widthSvg = 200, circleWidth = 200, bg = 'bg-transparent', context, fontSize }) => {
    const [porcentaje, setPorcentaje] = useState(0);
    const radius = 50;
    const dasharray = radius * Math.PI * 2;
    const dashOffset = dasharray - (dasharray * porcentaje) / 100;
    useEffect(() => {
        const percAmount =
            (
                context.tracing ? ((context?.tracing?.amount_1 / context?.user?.goal_amount_1) * 100) : 0
            ).toFixed(0)
        let perc = percAmount > 100 ? 100 : percAmount
        setPorcentaje(perc)
    }, [context?.tracing?.amount_1, context?.user?.goal_amount_1]);
    return (
        <>
            <svg className={bg} width={widthSvg} height={widthSvg} viewBox={`0 0 ${circleWidth} ${circleWidth}`}>
                <defs>
                    <linearGradient id="paint0_linear_1472_2523" x1="6" y1="126" x2="6" y2="6" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#89BD23" />
                        <stop offset="1" stopColor="#339140" />
                    </linearGradient>
                </defs>
                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth='2px'
                    r={radius}
                    className='circle-background'
                    gradientUnits="userSpaceOnUse"
                />
                <circle
                    cx={circleWidth / 2}
                    cy={circleWidth / 2}
                    strokeWidth='12px'
                    gradientUnits="userSpaceOnUse"
                    r={radius}
                    className='circle-progress'
                    stroke="url(#paint0_linear_1472_2523)"
                    style={{
                        strokeDasharray: dasharray,
                        strokeDashoffset: dashOffset,
                        transition: 'all 1s linear'
                    }}
                    transform={`rotate(-90 ${circleWidth / 2} ${circleWidth / 2} )`}
                />
                <text style={{ fontSize: `${fontSize && fontSize}px` }} fill="#007731" x='51%' y='55%' dy="0.3rem" textAnchor="middle" className="circle-text font-black">{`${porcentaje}` || 0}%</text>
            </svg>
        </>
    )
}

export default CircleProgress
