import React, { useEffect } from 'react'
import Container from '../../layouts/Container/Container'
import FaqsFeed from '../organisms/FaqsFeed'

function Faqs() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page faqs page-bg slow_ani'>
      <Container>
        <h2 className='text-center size-48 color-green pt-6'>Preguntas frecuentes</h2>

        <FaqsFeed
          faqsType={1}
          list={
            [
              {
                title: '1.¿Cuál es la vigencia de la campaña "Compra y Gana con Popular"?',
                content: " La campaña tiene vigencia desde el 07 de junio hasta el 07 de agosto de 2024."
              },
              {
                title: '2. ¿Qué se entiende por "PARTICIPANTE" en esta campaña?',
                content: " Los PARTICIPANTES son personas naturales que reciban comunicaciones del Banco sobre su participación en la campaña y posean una Tarjeta de Crédito Mastercard del Banco Popular."
              },
              {
                title: '3. ¿Cuál es el premio de la campaña y cómo se obtiene?',
                content: "El premio consiste en un BONO digital que se puede redimir en la página web: www.comprayganaconpopular.com una vez que el PARTICIPANTE alcance su meta de facturación."
              },
              {
                title: '4. ¿Qué son los "premios especiales" y quiénes pueden ganarlas?',
                content: "Son premios adicionales otorgados a los 3 mejores facturadores de cada uno de los 3 segmentos durante la vigencia de la campaña."
              },
              {
                title: '5. ¿Cómo se asignan las metas de facturación y los bonos correspondientes?',
                content: "Las metas y bonos se asignan según el segmento al que pertenezca el PARTICIPANTE, con base en su historial de compras y el valor de su tarjeta de crédito."
              },
              {
                title: '6. ¿Cuándo se puede redimir el BONO obtenido en la campaña?',
                content: "El BONO se puede redimir una vez que el PARTICIPANTE alcance su meta de facturación durante la vigencia de la campaña."
              },
              {
                title: '7.¿Cómo se redime el BONO obtenido en la campaña?',
                content: "El BONO se puede redimir en la galería de premios disponible en la página web: www.comprayganaconpopular.com"
              },
              {
                title: '8. ¿Cuántos BONOS puede redimir un PARTICIPANTE durante la campaña?',
                content: "Cada PARTICIPANTE puede redimir únicamente un (1) BONO durante toda la campaña."
              },
              {
                title: '9. ¿Qué sucede si un PARTICIPANTE pierde el PDF de su BONO?',
                content: "El PARTICIPANTE puede volver a descargar el PDF del BONO ingresando a la GALERÍA DE PREMIOS mientras la campaña esté vigente."
              },
              {
                title: '10. ¿Los BONOS son intercambiables por dinero en efectivo?',
                content: " No, los BONOS no son intercambiables por dinero en efectivo ni por otro bien o incentivo diferente a los aprobados por el Banco."
              },
              {
                title: '11. ¿Cómo se comunicará a los ganadores de bonos?',
                content: "Los ganadores serán notificados por correo electrónico y/o mensaje de texto para que revisen su perfil en la página web: www.comprayganaconpopular.com y canjeen su BONO digital."
              },
              {
                title: '12. ¿Qué sucede si un cliente cancela su tarjeta de Crédito del Banco Popular antes de recibir su reconocimiento promocional?',
                content: " Si el cliente cancela su tarjeta antes de recibir el reconocimiento, perderá el derecho al mismo."
              },
              {
                title: '13. ¿Cuáles son los requisitos para participar en la campaña?',
                content: " Los requisitos incluyen tener una Tarjeta de Crédito Mastercard del Banco Popular, acumular compras hasta alcanzar la meta indicada y realizarlas durante la vigencia de la campaña."
              },
              {
                title: '14. ¿Qué compras no son válidas para participar en la campaña?',
                content: "Las compras no válidas incluyen avances, pagos de impuestos, compras de cartera, entre otras, según lo especificado en los términos y condiciones."
              },
              {
                title: '15. ¿Qué sucede si un cliente realiza una compra después de finalizada la campaña?',
                content: "Las compras realizadas después de finalizada la campaña no aplican para obtener el beneficio."
              },
              {
                title: '16. ¿Qué hacer en caso de reclamo sobre la calidad o proceso de canje del BONO en el comercio?',
                content: "Los clientes podrán presentar una  PQRs a través del Banco para que su caso sea revisado y elevado al proveedor logístico Quantum Rewards. Este proveedor, contratado por Isobar, desarrollador de la plataforma de Campaña, y a su vez contratado por Mastercard Colombia, responderán a la solicitud en el menor tiempo posible."
              },
              {
                title: '17. ¿Qué pasa si un BONO no es redimido durante la vigencia de la campaña?',
                content: "Los BONOS no redimidos durante la vigencia de la campaña serán declarados desiertos. "
              },
              {
                title: '18. ¿Qué pasa si un cliente no accede a su perfil para redimir el BONO durante la campaña?',
                content: "Si el cliente no accede a su perfil para redimir el BONO antes del 31 de agosto de 2024, perderá el derecho de hacerlo y el BONO será considerado como no redimido."
              },
              {
                title: '19. ¿Cuál es el horario de atención para realizar reclamos sobre la calidad y proceso de canje del BONO?  ',
                content: "El horario de atención para reclamos es de lunes a viernes de 8:00 a. m. a 6:00 p. m."
              },
              {
                title: '20. ¿Qué pasa si se agotan las existencias de BONOS durante la campaña?',
                content: "Si se agotan las existencias de BONOS durante la campaña, los PARTICIPANTES podrán seguir cumpliendo su meta para participar por el premio aspiracional que son las experiencias priceless."
              },
            ]
          }
        />
      </Container>

    </div>
  )
}
export default Faqs