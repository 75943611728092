import React from 'react'

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'

const ArrowDto = ({todayDate}) => {
  return (
    <div className='ArrowDto' style={{"backgroundImage": `url(/assets/images/arrow_dto.png)`}}>
      <VerticalCenter>
        <p className='font-bold'>Descuentos para</p>
        <h4 className='color-green m-0 size-20'>hoy {todayDate === 'miercoles' ? 'miércoles' : todayDate === 'sabado' ? 'sábado' : todayDate}</h4>
      </VerticalCenter>
    </div>
  )
}

export default ArrowDto
