import React, { useContext } from 'react'
import Container from '../../layouts/Container/Container'
import Slider from 'react-slick'
import useWidth from '../../hooks/useWidth'
import Experience from '../molecules/Experience'
import { StateController } from '../../App'
import Preloader from '../atoms/Preloader'
import CustomModal from '../molecules/CustomModal'
// import SliderRotator from '../organisms/SliderRotator'
function Mechanic({showErrorDownload,setShowErrorDownload}) {
  const { context } = useContext(StateController)
  const width = useWidth()
  const list = [
    {
      id: 1,
      image: "step-1.png",
      title: "Usa tu tarjeta para todo",
      desc: "<p>Haz compras con tu Tarjeta de Crédito Mastercard Banco Popular.</p>"
    },
    {
      id: 2,
      image: "step-2.png",
      title: "Cumple tu meta y gana",
      desc: "<p>Cumple con tu meta asignada en facturación que encontrarás en la sección progreso.</p>"
    },
    {
      id: 3,
      image: "step-3.png",
      title: "¡Disfruta de tu bono!",
      desc: "<p>Elige tu bono favorito, completa tus datos, descárgalo y disfruta eso que tanto te gusta.</p>"
    },
  ]
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (

      <img
        className={className}
        style={{ ...style, display: "block", top: '110%', right: '0', zIndex: '100' }}
        onClick={onClick}
        src="/assets/images/mecanica/simple_arrow_right.svg" alt="" />

    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", top: '110%', left:'0', zIndex: '100' }}
        onClick={onClick}
        src="/assets/images/mecanica/simple_arrow_left.svg" alt="" />
    );
  }
  return (
    <div className='mc-page mechanic page-bg  slow_ani'>
      <Container>
        <h2 className='color-green size-48 text-center py-4'>¿Cómo ganar?</h2>
        {
          width?.width > 992 ?
            <section className="card-mecanica">
              {

                list.map((item, i) => (
                  <div key={i} className="card-mechanic">
                    <div className="imgMechanic">
                      <img src={`/assets/images/mecanica/${item?.image}`} alt="" />
                    </div>
                    <div className="titleMechanic text-center pb-2">
                      <h4 className='text-center m-0 color-green size-24'>{item?.title}</h4>
                    </div>
                    <div className='text-center' dangerouslySetInnerHTML={{ __html: item?.desc }} />
                  </div>
                ))
              }
            </section>

            :
            <section className='mechanic-Slider'>
              <Slider {...settings}>
                {

                  list.map((item, i) => (
                    <div key={i} className="card-mechanic">
                      <div className="imgMechanic text-center mx-auto">
                        <img style={{margin:'0 auto'}} src={`/assets/images/mecanica/${item?.image}`} alt="" />
                      </div>
                      <div className="titleMechanic text-center pb-2">
                        <h4 className='text-center m-0 color-green size-24'>{item?.title}</h4>
                      </div>
                      <div className='text-center content_card_mechanic' dangerouslySetInnerHTML={{ __html: item?.desc }} />
                    </div>
                  ))
                }
              </Slider>
            </section>

        }
        {/* <SliderRotator
          type={1}
          mobileItemsToShow={1}
          desktopItemsToShow={3}
          list={list}
        /> */}

        <section className='sectionExperience'>
        {
                  context ?
                    <Experience />
                    :
                    <Preloader
                      type="section"
                      invert
                    />

                }
        </section>
      </Container>
      <CustomModal
        title="Error en la redención"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        image="image.png"
        show={showErrorDownload} setShowModal={setShowErrorDownload}
        step={4}
      />
    </div >
  )
}
export default Mechanic