import React, { useEffect } from 'react'
import Container from '../../layouts/Container/Container'
// import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
// import draftToHtml from 'draftjs-to-html'
// import { StateController } from './../../App'
// import Container from '../../layouts/Container/Container'
// import Preloader from '../atoms/Preloader'

function Terms() {
  // const { staticContent } = useContext(StateController)
  // const [editorState, setEditorState] = useState(
  //   () => EditorState.createEmpty(),
  // )
  // const [termsHTML, setTermsHTML] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  // useEffect(() => {
  //   if (staticContent && staticContent.terms && staticContent.terms[0].content) {
  //     const json = JSON.parse(staticContent.terms[0].content)
  //     if (json && json.json && json.json.blocks) {
  //       setTermsHTML(true)

  //       setEditorState(
  //         EditorState.createWithContent(
  //           convertFromRaw(json.json)
  //         )
  //       )
  //     }
  //   }
  // }, [staticContent])

  return (
    // <div className='mc-page terms slow_ani'>
    //   <Container>
    //     <h1 className='text-center size-48 color-green pt-6'>Términos y condiciones</h1>

    //     {termsHTML
    //       ? <div className='mc-static-content'>
    //           <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }} />
    //         </div>
    //       : <Preloader
    //           type="section"
    //           invert
    //         />
    //     }
    //   </Container>

    // </div>

    <>
      <div className='mc-page terms page-bg slow_ani'>
        <Container>
          <h2 className='text-center size-48 color-green py-6'>Términos y condiciones</h2>
          <section className='tyc'>
            <article>
              <p>
                Los términos y condiciones de la Campaña, serán aceptados por los participantes al momento de ingresar a la página web: <a href="http://www.comprayganaconpopular.com" target="_blank" rel="noopener noreferrer">www.comprayganaconpopular.com</a>
              </p>
              <p>
                Al hacer clic en la casilla de términos y condiciones, los clientes también aceptan su participación en la campaña, incluyendo el tratamiento de datos personales. Esto permitirá enviarles, de ahora en adelante, todas las comunicaciones relacionadas con la campaña a través de diferentes medios como correos electrónicos, mensajes de texto, pautas dirigidas en redes sociales y cualquier comunicación futura sobre nuevas campañas o información relevante de descuentos o promociones exclusivas para los tarjetahabientes del banco.
              </p>

            </article>

            <article>
              <h4>1.	VIGENCIA DE LA CAMPAÑA:</h4>
              <p>
                La campaña Compra y Gana con Popular (en adelante la “CAMPAÑA”) tendrá vigencia desde el 07 de junio de 2024 hasta el 07 de agosto de 2024.
              </p>
            </article>

            <article>
              <h4>2.	DEFINICIONES:</h4>
              <ul>
                <li>
                  <p>
                    <strong>•	PARTICIPANTE:</strong> Significan las personas naturales, que reciban una comunicación vía correo electrónico por el Banco en las que se informe sobre su participación en la presente CAMPAÑA y que tengan una Tarjeta de Crédito del portafolio Mastercard Banco Popular activa, en recencia o inactividad en su uso.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>•	RECENCIA:</strong> medida del tiempo transcurrido desde la última transacción que realizó un cliente con su tarjeta de Crédito del portafolio Mastercard Banco Popular.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>•	PREMIO o BONO:</strong> Significa el premio que recibirán los PARTICIPANTES al cumplir su meta el cual podrá ser redimido en la página web: <a href="http://www.comprayganaconpopular.com" target="_blank" rel="noopener noreferrer">www.comprayganaconpopular.com</a> una vez cumplan la meta establecida en la campaña, la cual se visualizará dentro de cada perfil en la página web:  <a href="http://www.comprayganaconpopular.com" target="_blank" rel="noopener noreferrer">www.comprayganaconpopular.com</a> Los premios de los participantes serán bonos digitales, de diferentes comercios, que el ganador deberá descargar para hacerlo efectivo en el comercio de su elección según disponibilidad. Además, dentro de la bolsa de premios se incluirán experiencias priceless, que se otorgarán a los 3 mejores facturadores de cada uno de los 3 segmentos.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>•	SEGMENTO:</strong> Distribución de clientes en campaña, relacionada directamente con el cupo de su tarjeta de crédito del portafolio Mastercard entregada. Los clientes fueron segmentados en 3 grupos integrados por clientes con cupo similar, a los cuales les fue asignado un monto mínimo de compra que deberá cumplir para obtener su PREMIO o BONO proporcional a su valor de facturación durante la campaña.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>•	COMERCIOS:</strong> Significan los establecimientos nacionales e internacionales, que están habilitados en la GALERÍA DE PREMIOS, para llevar a cabo la redención de BONOS.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>•	GALERÍA DE PREMIOS:</strong> Significa la página web: <a href="http://www.comprayganaconpopular.com" target="_blank" rel="noopener noreferrer">www.comprayganaconpopular.com</a> a través de la cual los PARTICIPANTES podrán revisar el catálogo de bonos digitales disponibles para redimir.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>•	COMPRAS/PAGOS NO PRESENCIALES:</strong> son las transacciones comerciales (compras, pagos y transferencias) que se hacen a través de comercio electrónico.
                  </p>
                </li>
              </ul>







            </article>

            <article>
              <h4>3.	MECÁNICA DE LA CAMPAÑA:</h4>
              <p>
                La mecánica general de la CAMPAÑA consiste en incentivar a los PARTICIPANTES mediante el otorgamiento de un premio para redimir un (1) BONO a través de la GALERÍA DE PREMIOS. Cuando el PARTICIPANTE (i) haya realizado compras que sumen el valor de su meta de facturación indicada vía correo electrónico y/o mensaje de texto, dependiendo del segmento al que pertenezca, se otorgará el valor del bono digital correspondiente. El PARTICIPANTE tendrá tiempo para realizar sus compras y cumplir con la meta asignada durante la vigencia de la campaña.
              </p>

              <p>
                Como incentivo adicional, se otorgarán 3 premios mayores a los 3 tarjetahabientes que cumplan su meta el mayor número de veces y que hayan realizado la apertura de al menos una de las comunicaciones durante la vigencia de la campaña. Las experiencias serán distribuidas de la siguiente manera: Segmento 1: Viaje a Santa Marta para el titular y un acompañante, Segmento 2: Cena Gourmet para para el titular y un acompañante, y Segmento 3: Un bono de tecnología.
              </p>
              <p>
                El reto de facturación en la “CAMPAÑA” es exclusivo para los tarjetahabientes con Tarjeta de Crédito del portafolio Mastercard Banco Popular persona natural, y que hayan sido seleccionados por el Banco (por recencia, inactividad o que nunca han usado su tarjeta de crédito).
              </p>
              <p>
                Deben estar al día en sus pagos de obligaciones con el Banco Popular, en caso de no estar al día en los productos con el Banco Popular y si es uno de los ganadores, el Banco podrá tomar la determinación de no entregar el reconocimiento promocional. Pueden participar clientes menores de edad bajo la supervisión de su tutor o representante legal. No es válida la participación de los clientes personas jurídicas con tarjeta crédito. Del mismo modo, no participan las Tarjetas de Crédito Corporativas, ni las que no se hayan mencionado o comunicado en estos términos y condiciones. Se debe cumplir con la meta de facturación indicada vía correo electrónico, y determinada según la segmentación.
              </p>

              <p>
                De acuerdo con el segmento al que pertenece, deberá efectuar compras hasta alcanzar la meta de facturación asignada. Cumpliendo con esta meta, podrá acceder al respectivo bono asignado a su segmento:
              </p>
              <h4>
                <u>A.	TABLA DE BONOS POR SEGMENTO:</u>
              </h4>

              <table className='my-4' style={{ width: '70%' }} align='center' border={1}>
                <thead>
                  <tr>
                    <th>Segmento</th>
                    <th>Valor bono </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align='center'>1</td>
                    <td align='center'>$400.000 ó $500.000</td>
                  </tr>
                  <tr>
                    <td align='center'>2</td>
                    <td align='center'>$30.000, $50.000, $70.000, $100.000, $150.000, $200.000, $300.000, $400.000, $500.000,</td>
                  </tr>
                  <tr>
                    <td align='center'>3</td>
                    <td align='center'>$30.000</td>
                  </tr>
                </tbody>
              </table>
            </article>

            <article>
              <h4>4.	BONOS:</h4>
              <ul>
                <li>
                  <p>
                    •	Cada PARTICIPANTE podrá redimir únicamente un (1) BONO durante la CAMPAÑA.
                  </p>
                </li>
                <li>
                  <p>
                    •	Los BONOS sólo podrán ser redimidos en la galería de premios publicada en: <a href="http://www.comprayganaconpopular.com" target="_blank" rel="noopener noreferrer">www.comprayganaconpopular.com</a>
                  </p>
                </li>
                <li>
                  <p>
                    •	Únicamente podrán ser usados para adquirir los productos en el COMERCIO seleccionado de la galería de premios.
                  </p>
                </li>
                <li>
                  <p>
                    •	Todos los BONOS tienen sus propios términos y condiciones para ser canjeados de forma específica según el comercio elegido. Dicha información podrá ser consultada por los PARTICIPANTES en el archivo PDF que podrá descargar en la plataforma de su bono.
                  </p>
                </li>
                <li>
                  <p>
                    •	Si el cliente pierde el PDF de su BONO, puede volver a descargarlo, ingresando a la GALERÍA DE PREMIOS con sus datos de acceso, mientras la campaña se encuentre vigente.
                  </p>
                </li>
                <li>
                  <p>
                    •	Los BONOS no son intercambiables por dinero en efectivo ni por otro bien o incentivo diferente a los aprobados por el Banco. No se realizarán reversiones ni devoluciones de los BONOS.
                  </p>
                </li>
                <li>
                  <p>
                    •	En ningún caso, EL BONO podrá ser cedido o vendido por el PARTICIPANTE.
                  </p>
                </li>
                <li>
                  <p>
                    •	EL PARTICIPANTE únicamente podrá redimir uno de los BONOS que estén disponibles en la galería de premios. Las cantidades disponibles de los BONOS estarán sujetas a la disponibilidad de inventario. Por ende, una vez se agote el BONO se deshabilitará su redención en el catálogo y el PARTICIPANTE que active y use su tarjeta, deberá elegir cualquier otro BONO de la galería.
                  </p>
                </li>
                <li>
                  <p>
                    •	El Banco no asume responsabilidad alguna por los reclamos presentados en cuanto a la calidad, cantidad, estado, proceso de entrega y utilización de los productos o servicios adquiridos con los BONOS, los COMERCIOS serán los únicos responsables por la garantía y condiciones de estos. El Banco tampoco asume responsabilidad alguna por el agotamiento de las existencias de los BONOS. En caso de reclamo en cuanto a la calidad y proceso de canje del BONO en el comercio, los participantes podrán presentar una  PQRs a través del Banco para que su caso sea revisado y elevado al proveedor logístico Quantum Rewards. Este proveedor, contratado por Isobar, desarrollador de la plataforma de Campaña, y a su vez contratado por Mastercard Colombia, responderán a la solicitud en el menor tiempo posible.
                  </p>
                </li>
                <li>
                  <p>
                    •	Si se presenta algún reclamo sobre la plataforma en la que está habilitada la galería de premios, el Banco trasladará dicho reclamo a MASTERCARD COLOMBIA.
                  </p>
                </li>
                <li>
                  <p>
                    •	Si durante el término de duración de campaña el PARTICIPANTE no accedió a su perfil para hacer redención del BONO antes del 31 de agosto de 2024, perderá el derecho de hacerlo. El Banco no reconocerá ningún caso que sea reclamado con posterioridad a las fechas establecidas en la vigencia de la CAMPAÑA. Los BONOS que no sean redimidos por los PARTICIPANTES durante la vigencia o con posterioridad a dicha fecha, serán declarados desiertos. En total son 600 BONOS disponibles.
                  </p>
                </li>
                <li>
                  <p>
                    •	Los BONOS DIGITALES cuentan con fechas de vigencia estipuladas por cada COMERCIO, las cuales se indicarán en el documento PDF disponible al descargarlo.
                  </p>
                </li>
              </ul>
            </article>

            <article>
              <h4>5.	PREMIOS MAYORES: </h4>
              <p>
                Se otorgarán 3 premios mayores a los 3 tarjetahabientes que cumplan su meta el mayor número de veces y que hayan realizado la apertura de al menos una de las comunicaciones durante la vigencia de la campaña. Los premios serán distribuidas de la siguiente manera: Segmento 1: Viaje a Santa Marta para el titular y un acompañante, Segmento 2: Cena Gourmet para para el titular y un acompañante, y Segmento 3: Un bono de tecnología. En caso de empate entre dos participantes, se seleccionará al participante que haya cumplido su meta primero o al que tenga el mayor monto de facturación.
              </p>
              <p>
                <strong>A. Premio Mayor Segmento 1:</strong> Un Viaje a Santa Marta [para el titular y un acompañante mayor de edad]:
              </p>
              <p>
                <strong>Incluye:</strong>
              </p>

              <ul>
                <li>
                  <p>
                    •	Hospedaje en el Hotel Hotel Marriot Playa dormida [Playa privada]
                  </p>
                </li>
                <li>
                  <p>
                    •	Alojamiento 3 días 2 noches
                  </p>
                </li>
                <li>
                  <p>
                    •	Experiencia gastronómica en Casa Magdalena
                  </p>
                </li>
                <li>
                  <p>
                    •	Kit de bienvenida [snacks especiales 1ra. noche]
                  </p>
                </li>
                <li>
                  <p>
                    •	Tiquetes aéreos Bogotá – Santa Marta – Bogotá con artículo personal y equipaje de mano de 10 kilos
                  </p>
                </li>
                <li>
                  <p>
                    •	Traslados aeropuerto – hotel – aeropuerto
                  </p>
                </li>
                <li>
                  <p>
                    •	Seguro de viaje
                  </p>
                </li>
                <li>
                  <p>
                    •	Tarjeta prepagada USD 200 [comidas, bebidas y experiencias de viaje]
                  </p>
                </li>
                <li>
                  <p>
                    •	Tarjeta de asistencia médica
                  </p>
                </li>
              </ul>

              <p>
                <strong>A tener en cuenta:</strong>
              </p>

              <ul>
                <li>
                  <p>
                    •	El viaje es válido para el ganador y un acompañante a la ciudad de Santa Marta.
                  </p>
                </li>
                <li>
                  <p>
                    •	El viaje deberá ser reclamado antes de 6 meses, una vez se dé el anuncio al ganador.
                  </p>
                </li>
                <li>
                  <p>
                    •	El disfrute del premio estará sujeto a la disponibilidad de vuelos y del hotel.
                  </p>
                </li>
                <li>
                  <p>
                    •	El premio no es transferible ni canjeable por dinero en efectivo.
                  </p>
                </li>
                <li>
                  <p>
                    •	Los ítems que no están descritos en el presupuesto deberán ser asumidos directamente por el ganador.
                  </p>
                </li>
                <li>
                  <p>
                    •	El viaje no podrá realizarse durante temporadas altas
                  </p>
                </li>
              </ul>

              <p>
                <strong>B. Premio Mayor Segmento 2:</strong> Una Cena Gastronómica [para el titular y un acompañante mayor de edad]:
              </p>

              <ul>
                <li>
                  <p>
                    •	El valor de la cena será por un monto máximo de un valor de COP $1.500.000.
                  </p>
                </li>
                <li>
                  <p>
                    •	El premio deberá ser reclamado antes de 6 meses, una vez se anuncie al ganador.
                  </p>
                </li>
                <li>
                  <p>
                    •	El restaurante deberá ser elegido por el ganador, dentro de la propuesta de restaurantes seleccionados para la redención de este premio. Se enviarán tres opciones al ganador para que escoja la que más le guste.
                  </p>
                </li>
                <li>
                  <p>
                    •	La cena es válida únicamente en la ciudad más cercana al ganador, según la lista de restaurantes disponibles.
                  </p>
                </li>
                <li>
                  <p>
                    •	El valor de la cena es únicamente por solo una vez y no es acumulable con otras promociones, descuentos u ofertas especiales de la marca.
                  </p>
                </li>
                <li>
                  <p>
                    •	La cena no es transferible, ni canjeable por dinero en efectivo.
                  </p>
                </li>
                <li>
                  <p>
                    •	El traslado al restaurante deberá ser cubierto por el ganador.
                  </p>
                </li>
              </ul>
              <p>
                <strong>C.      Premio Mayor Segmento 3:</strong> Una Gift Card Digital [Bono tecnología]
              </p>

              <ul>
                <li>
                  <p>
                    •	El valor del premio será por un monto máximo de un valor de COP $1.100.000.
                  </p>
                </li>
                <li>
                  <p>
                    •	Para la redención del bono de tecnología, el ganador tendrá la opción de escoger entre 4 comercios: Ktronix, Falabella Empresas, Jumbo y Flamingo  para realizar la redención del bono.
                  </p>
                </li>
                <li>
                  <p>
                    •	El premio deberá ser reclamado antes de 6 meses, una vez se anuncie al ganador.
                  </p>
                </li>
                <li>
                  <p>
                    •	La redención del bono de tecnología deberá realizarse de acuerdo con las políticas de cada uno de los comercios seleccionados.
                  </p>
                </li>
                <li>
                  <p>
                    •	El comercio para la redención del bono deberá ser elegido por el ganador, dentro de la propuesta de comercios seleccionados para la redención de este premio. Se enviarán cuatro opciones de comercio al ganador para que escoja la que más le guste.
                  </p>
                </li>
                <li>
                  <p>
                    •	La redención del bono es válida únicamente para la ciudad más cercana al ganador, según la lista de comercios disponibles.
                  </p>
                </li>
                <li>
                  <p>
                    •	El valor del bono es únicamente por solo una vez y no es acumulable con otras promociones, descuentos u ofertas especiales de la marca.
                  </p>
                </li>
                <li>
                  <p>
                    •	El bono no es transferible, ni canjeable por dinero en efectivo.
                  </p>
                </li>
                <li>
                  <p>
                    •	El traslado al comercio para la redención del premio deberá ser cubierto por el ganador.
                  </p>
                </li>
              </ul>

            </article>

            <article>
              <h4>6.	PARTICIPANTES:</h4>
              <p>
                Participan las tarjetas de Crédito del portafolio Mastercard, persona natural de clientes con recencia o quienes no hayan hecho una primera compra. Se excluyen reexpediciones, reposiciones y renovaciones de tarjeta de crédito.
              </p>
            </article>
            <article>
              <h4>7.	REQUISITOS PARA PARTICIPAR:</h4>
              <ul>
                <li>
                  <p>
                    a)	Se tendrán en cuenta las tarjetas de Crédito de portafolio Mastercard de clientes con recencia o que no han hecho una primera compra con sus Tarjetas de crédito Mastercard, durante el último periodo.
                  </p>
                </li>
                <li>
                  <p>
                    b)	Aplica para las compras que sumadas lleguen a la meta indicada vía correo electrónico y/o mensaje de texto, durante la duración de la campaña.
                  </p>
                </li>
                <li>
                  <p>
                    c)	Si la Tarjeta se usa después del último día de la Campaña, no aplica para otorgarle el beneficio.
                  </p>
                </li>
                <li>
                  <p>
                    d)	El beneficio que se le brindará a los clientes con tarjeta de Crédito del portafolio Mastercard, que hagan compras hasta alcanzar la meta indicada, durante la vigencia de la campaña es un bono digital de acuerdo con monto facturado que se indica en la tabla del capítulo 3 de estos términos y condiciones.
                  </p>
                </li>
                <li>
                  <p>
                    e)	Aplicará el otorgamiento del bono digital, por la realización de compras presenciales en establecimientos con Tarjeta Crédito del portafolio Mastercard a nivel Nacional e Internacional y en compras No presenciales Nacionales e Internacionales.
                  </p>
                </li>
                <li>
                  <p>
                    <u>
                      f)	Se deben excluir las compras efectuadas de forma no presencial utilizando el botón PSE ni transacciones en cajeros automáticos o transacciones realizadas por el portal web o los canales electrónicos del Banco, tampoco aplica para avances con Tarjeta de Crédito, Pago de Obligaciones, Compras de Cartera, Pago de Impuestos, Novación, Seguros de Vida, Seguros Cardif, Pagos Recurrentes, Domiciliaciones ni Compras de Origen Fraudulento. Del mismo modo, se deben excluir las compras asociadas al pago de Servicios públicos o pago de trámites ante ciertas entidades públicas, tales como, pago de Impuestos, Tránsito, Catastro, Cades, Registradurías y Notarías.
                    </u>
                  </p>
                </li>
                <li>
                  <p>
                    g)	Para la Campaña se tendrá en cuenta la compra por el valor mínimo indicado en pesos colombianos.
                  </p>
                </li>
                <li>
                  <p>
                    h)	No aplica para primer uso en compra de cartera, avances o pago de impuestos.
                  </p>
                </li>
              </ul>

              <h4>Tabla de Grupos, metas y reconocimientos:</h4>
              <p>
                Ganan los clientes que cumplen con su meta mínima de facturación en sus compras acumuladas con su tarjeta de Crédito del portafolio Mastercard. La notificación sobre el reconocimiento promocional será enviada a los clientes por medio de mail indicando que debe revisar su perfil en: <a href="http://www.comprayganaconpopular.com" target="_blank" rel="noopener noreferrer">www.comprayganaconpopular.com</a>, y canjear su bono digital.
              </p>
              <p>
                Cada cliente tendrá asignada una meta de facturación y un BONO asignado por el cumplimiento de esta durante la vigencia de la campaña. El valor de la compra mínima y el valor del reconocimiento promocional por producto serán informados tal y como se señala en la siguiente tabla, el cliente recibirá un BONO sobre el valor total de las compras, por el valor mínimo indicado.
              </p>
              <h4><u>B. TABLA DE BONOS POR SEGMENTO:</u></h4>
              <table className='my-4' style={{ width: '70%' }} align='center' border={1}>
                <thead>
                  <tr>
                    <th>Segmento</th>
                    <th>Valor bono </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align='center'>1</td>
                    <td align='center'>$400.000 ó $500.000</td>
                  </tr>
                  <tr>
                    <td align='center'>2</td>
                    <td align='center'>$30.000, $50.000, $70.000, $100.000, $150.000, $200.000, $300.000, $400.000, $500.000,</td>
                  </tr>
                  <tr>
                    <td align='center'>3</td>
                    <td align='center'>$30.000</td>
                  </tr>
                </tbody>
              </table>

              <ul>
                <li>
                  <p>
                    i)	Para participar y ganar, los clientes con tarjeta de Crédito del portafolio Mastercard, que presenten recencia, o no hayan hecho su primera compra deberán acumular compras hasta lograr la meta asignada, dentro de la vigencia de campaña. Lo anterior, como primer requisito para acceder al reconocimiento promocional.
                  </p>
                </li>
                <li>
                  <p>
                    Cada cliente tiene asignado una meta de facturación a realizar para poder acceder a un BONO por un valor determinado.
                  </p>
                </li>
                <li>
                  <p>
                    j)	El ganador deberá tener su tarjeta de Crédito del portafolio Mastercard del Banco Popular hasta tanto no se verifique la entrega del reconocimiento promocional establecido, si el cliente cancela su tarjeta de Crédito del portafolio Mastercard, antes de la entrega del reconocimiento, acepta que perderá el mismo.F
                  </p>
                </li>
                <li>
                  <p>
                    k)	El cliente podrá ser ganador del reconocimiento promocional por cada uno de los plásticos de tarjeta de Crédito del portafolio Mastercard que presenten recencia o no registre primer uso en el sistema, asignados a él y que hayan sido seleccionados para ser incluidos en la Campaña.
                  </p>
                </li>
                <li>
                  <p>
                    l)	Al participar en esta campaña, los tarjetahabientes autorizan al Banco Popular para monitorear las compras, facturación de sus tarjetas y cuentas para fines estadísticos.
                  </p>
                </li>
                <li>
                  <p>
                    m)	El Banco Popular podrá descalificar al cliente que incurra en conductas fraudulentas, irregulares, contrarias a la Ley o que sean contrarias a estos términos y condiciones.
                  </p>
                </li>
                <li>
                  <p>
                    n)	El Banco Popular se reserva el derecho de suspender o cancelar en forma parcial o total la Campaña por caso fortuito o fuerza mayor; o cuando se compruebe que en el desarrollo de la Campaña se ha cometido algún fraude.
                  </p>
                </li>
                <li>
                  <p>
                    o)	El Banco Popular se reserva el derecho de modificar los términos y condiciones de la Campaña y la mecánica, previo aviso enviado por cualquier medio idóneo que disponga.
                  </p>
                </li>
                <li>
                  <p>
                    p)	No aplica para los clientes cuyas Tarjetas Crédito se encuentren bloqueadas definitivamente o canceladas.
                  </p>
                </li>
                <li>
                  <p>
                    q)	Sólo se tendrán en cuenta las transacciones realizadas durante la vigencia de la campaña, según la fecha en la que se haya realizado la transacción.
                  </p>
                </li>
              </ul>
            </article>

            <article>
              <h4>8.	BOLSA DE RECONOCIMIENTOS  </h4>
              <ul>
                <li>
                  <p>
                    a)	La bolsa de reconocimientos para la promoción será de: 600 BONOS, disponible entre el 07  de junio al 7 de agosto de 2024 o hasta agotar existencias de la bolsa de reconocimientos disponible.
                  </p>

                </li>
                <li>
                  <p>
                    b)	El reconocimiento promocional (bono digital) no podrá ser entregado a través de un mecanismo diferente al mencionado en el numeral 1 del capítulo 7. Tampoco será cedido o vendido a terceros; éste sólo será entregado al ganador y titular de la tarjeta de Crédito del portafolio Mastercard, con la que realizó las compras. <u>El reconocimiento promocional será entregado hasta agotar la bolsa de reconocimientos disponibles de acuerdo con lo mencionado en el numeral 1, del capítulo 7.</u>
                  </p>

                </li>
              </ul>
              <p>
                Los premios aspiracionales consistirán en 3 premios especiales, los cuales serán distribuidos de la siguiente manera: Segmento 1: Viaje a Santa Marta para el titular y un acompañante, Segmento 2: Cena Gourmet para para el titular y un acompañante, y Segmento 3: Un bono de tecnología.
              </p>
              <h4>En caso de cualquier inquietud el cliente se podrá comunicar a la línea telefónica del Banco Popular en su ciudad: Bogotá (601) 743 46 46 o línea nacional 01 8000 18 4646. </h4>
              <h4>CONDICIONES ESPECIALES:</h4>
              <ul>
                <li>
                  <p>
                    SUSPENSIÓN DE LA CAMPAÑA: El Banco podrá suspender de forma inmediata la presente CAMPAÑA, sin asumir responsabilidad alguna, si se llegasen a detectar delitos, fraudes o cualquier irregularidad en la forma de participar, en la forma de realizar transacciones, o en el comportamiento de alguno de los PARTICIPANTES, o si se presentara alguna circunstancia de fuerza mayor o caso fortuito que afecte los intereses o buena fe del Banco. Esta circunstancia o alguna modificación en los términos y condiciones se comunicarán públicamente a través de la Galería de Premios o a través de otro medio y/o canal de conformidad con la Ley.
                  </p>

                </li>
                <li>
                  <p>
                    COBERTURA GEOGRÁFICA: La CAMPAÑA estará vigente en el territorio de la República de Colombia, aplican compras a nivel nacional e Internacional, realizadas con las Tarjetas de Crédito del portafolio Mastercard. No aplican compras a través de PSE, ni transferencias, ni las mencionadas en el numeral 6 del capítulo 6.
                  </p>

                </li>
              </ul>
            </article>
            <article>
              <h4>8. EXONERACIÓN DE RESPONSABILIDAD:</h4>
              <p>
                <strong>PARÁGRAFO 1°:</strong> El cliente conoce y acepta las condiciones establecidas en el presente documento y se adhiere a ellas para acceder a los BONOS. Los términos y condiciones de la campaña son aceptados por los participantes en la página de la campaña: <a href="http://www.comprayganaconpopular.com" target="_blank" rel="noopener noreferrer">www.comprayganaconpopular.com</a>
              </p>
            </article>
          </section>
        </Container>
      </div>
    </>
  )
}
export default Terms