import React from 'react'
import Slider from 'react-slick/lib/slider';

const AwardsBanner = ({
    slider = false
}) => {
    const settings = {
        className: "center slick-logos",
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        // autoplay: true,
        arrows: false
    };

    if (slider) {
        return (
            <Slider {...settings}>
                <div className="logos ">
                    <img className='mx-auto' src="/assets/images/galeria/crepes.png" alt="" />
                </div>
                <div className="logos ">
                    <img className='mx-auto' src="/assets/images/galeria/totto.png" alt="" />
                </div>
                <div className="logos ">
                    <img className='mx-auto' src="/assets/images/galeria/jumbo.png" alt="" />
                </div>
                <div className="logos ">
                    <img className='mx-auto' src="/assets/images/galeria/alkosto.png" alt="" />
                </div>
                <div className="logos ">
                    <img className='mx-auto' src="/assets/images/galeria/falabella.png" alt="" />
                </div>
                <div className="logos ">
                    <img className='mx-auto' src="/assets/images/galeria/ktronix.png" alt="" />
                </div>
            </Slider>
        )
    } else {
        return (
            <div className='AwardsBanner my-4'>
                <div className="awards_log">
                    <div className="logos">
                        <img className='mx-auto' src="/assets/images/galeria/crepes.png" alt="" />
                    </div>
                    <div className="logos">
                        <img className='mx-auto' src="/assets/images/galeria/totto.png" alt="" />
                    </div>
                    <div className="logos">
                        <img className='mx-auto' src="/assets/images/galeria/jumbo.png" alt="" />
                    </div>
                    <div className="logos">
                        <img className='mx-auto' src="/assets/images/galeria/alkosto.png" alt="" />
                    </div>
                    <div className="logos">
                        <img className='mx-auto' src="/assets/images/galeria/falabella.png" alt="" />
                    </div>
                    <div className="logos">
                        <img className='mx-auto' src="/assets/images/galeria/ktronix.png" alt="" />
                    </div>

                </div>
            </div>
        )

    }
}

export default AwardsBanner
