import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';

const SliderAlied = ({ list }) => {
  const [listFiltered, setListFiltered] = useState(false);

  useEffect(() => {
    if (list) {      
      const result = list.filter((item) => item.subDescription === 'semanario')
      setListFiltered(result)
    }

  }, [list])

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (

      <img
        className={className}

        onClick={onClick}
        src="/assets/images/mecanica/simple_arrow_right.svg" alt="" />

    );
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <img
        className={className}
        onClick={onClick}
        src="/assets/images/mecanica/simple_arrow_left.svg" alt="" />
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay:true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      }
    ]
  };

  return (
    <div className={`SliderAlied size-${listFiltered.length}`}>
      <div className="slider-container">
        {listFiltered &&
          <Slider {...settings}>
            {listFiltered &&
              listFiltered.map((item, index) => {                
                return (
                  <div key={index} className='items'>
                    <div key={index} className='items'>
                      <div className="content mx-2">
                        <div className="imgBg">
                          <img src={`/assets/images/alianzas/${item?.banner}`} alt="" />
                        </div>
                        <div className='info'>
                          <div className={`dataAllied ${item?.name}`}>
                            <div className='logoAllied'>
                              <img src={`/assets/images/alianzas/${item?.image}`} alt='Logo alianza' />

                            </div>
                            <div>
                              <h4 className='m-0'>{item?.name}</h4>
                              <p className='rule'>
                                {

                                  item?.rule

                                }
                              </p>
                              <p className='store'>
                                {item?.description} <a href={`/assets/images/alianzas/pdf/${item?.link_file}`} target="_blank" rel="noopener noreferrer">Ver T&C.</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })

            }
          </Slider>
        }
        
      </div>
    </div>
  )
}

export default SliderAlied
