import React, { useState, useEffect } from 'react'

import Icon from './Icon'
import {sendDataLayer} from '../../utils/functions'
function Accordeon({
  allItems, 
  setAllItems, 
  title, 
  content
}) {
  const [show, setShow] = useState(false)

  const toggler = (e) => {
    setAllItems(!allItems)

    if (e.target.closest('.mc-accordeon-container.active')) {
      return false
    }

    setTimeout( () => {
      setShow(true)
    }, 50 )
  }

  useEffect(() => {
    setShow(false)
  }, [allItems])

  return (
    <div className={`mc-accordeon-container slow_ani ${show ? 'active' : ''}`}>
      <div
        className="mc-accordeon-toggle slow_ani color-green2"
        onClick={(e) => {
          toggler(e)
          sendDataLayer({
            datalayer: {
              event: "frequently_questions",
              question: "¿Como puedo cumplir mi meta?"
                            
            },repeat: true}) 
        }}
      >
        {title}
        <div className="mc-accordeon-toggle-icon">
          <Icon name="faq" />
        </div>
      </div>
      {show &&
        <div className="mc-accordeon-content">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      }
    </div>
  )
}

export default Accordeon