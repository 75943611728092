import React from 'react'
import { NavLink } from 'react-router-dom'

const Dropdown = ({ pages, logOutAction }) => {
    return (
        <ul className='Dropdown'>
            <li className='rectangle'><img src="/assets/images/header/Rectangle.svg" alt="" /></li>
            {
                pages ?
                    pages.map((item, i) => (
                        <li className='item-submenu' key={i}>
                            <NavLink to={`/${item?.page}`} className={'link-submenu'}>
                                <span className='icon'>
                                    <img src={`/assets/images/header/${item?.icon}`} alt={`Icono de ${item?.title}`} />
                                </span>
                                <span className="textSubmenu">
                                    {
                                        item?.title
                                    }
                                </span>
                            </NavLink>
                        </li>
                    ))
                    :
                    <></>
            }
            <li onClick={logOutAction} className='item-submenu'>
                <div className="link-submenu">

                <span className='icon'>
                    <img src={`/assets/images/header/logout.png`} alt={`Icono de cerrar sesión`} />
                </span>
                <span className='textSubmenu logout'>
                    Cerrar sesión
                </span>
                </div>
            </li>
        </ul>
    )
}

export default Dropdown
