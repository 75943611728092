import React from 'react'
import Input from '../atoms/Input'

const FormQuantumData = ({ location, setQuantumName,setQuantumDoc, quantumName, quantumDoc}) => {
	const handleChangeName = async (e) => {
		setQuantumName(e.target.value)
	}
	const handleChangeDoc = async (e) => {
		const numbers = /^\d+$/
		if((numbers.test(e.target.value) || e.target.value === '') && e.target.value.length < 13){
			setQuantumDoc(e.target.value)			
		}
	}

	return (
		<div className='FormQuantumData my-1'>
			<p className='size-13 text-center'>
				Completa la siguiente información para hacer efectiva la redención de tu premio
			</p>

			<div className="dataQuantum">
				{
					(location && location === 1) ?
						<div>
							<Input value={quantumName} onChange={handleChangeName} type={'dataUser'} placeholder={'Nombre completo'} />
							<Input value={quantumDoc} onChange={handleChangeDoc} type={'dataUser'} placeholder={'Número de documento'} />
						</div>
						:
						(location && location === 2) ?
							<div>
								<Input type={'select'} placeholder={'Departamento'} />
								<Input type={'select'} placeholder={'Ciudad'} />
								<Input type={'select'} placeholder={'Sitio'} />
							</div>
							:
							<></>

				}

			</div>
		</div>
	)
}

export default FormQuantumData
