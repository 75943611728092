import React, { useContext, useEffect, useState } from 'react'
import { StateController } from './../../App'
import Container from '../../layouts/Container/Container'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'
import { getMaximumBlock } from '../../utils/functions'
import Experience from '../molecules/Experience'
import useWidth from '../../hooks/useWidth'


function Awards() {
  const [awards, setAwards] = useState(false)
  const [canRedem, setCanRedem] = useState(false)
  const { context } = useContext(StateController)
  const width = useWidth()

  useEffect(() => {

    if (context && context.awards) {
      setAwards(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].awards
      )
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      )
    }
  }, [context])

  return (
    <div className='mc-page awards page-bg slow_ani'>
      <section>
        <article className='border-bottom-64 mb-5 border-bottom-64-md'>
          <Container>
            <h2 className='color-green size-48 text-center pt-5 pb-4'>Elige tu bono</h2>
            <p className='color-gray size-24 text-center pb-6'>Canjea el bono que más te guste, descárgalo y disfrútalo en tu comercio favorito.</p>
          </Container>

        </article>
      </section>

      <Container>
        {awards
          ? <AwardsGallery
            awardBoxType={1}
            itemsPerLine={ width?.width > 992 ? 3 : 2 }
            // limit={3}
            limitButtonText="Ver más comercios"
            showStock={false}
            list={awards}
            canRedem={canRedem}
            redemtionText="Redimir"
          // termText="Términos y condiciones"
          // termLink="/terminos-y-condiciones"
          />
          : <Preloader
            type="section"
            invert
          />
        }


        {/* <AwardsGallery
            awardBoxType={1}
            itemsPerLine={3}
            steps={
              [
                {
                  name: "Oportunidad 1",
                  awards: [
                    {
                      id: 1,
                      name: "Comercio prueba",
                      price: 40000,
                      image: "award.png"
                    },
                    {
                      id: 1,
                      name: "Comercio prueba 2",
                      price: 40000,
                      image: "award.png"
                    }
                  ]
                },
                {
                  name: "Oportunidad 2",
                  awards: [
                    {
                      id: 1,
                      name: "Comercio prueba",
                      price: 40000,
                      image: "award.png"
                    },
                    {
                      id: 1,
                      name: "Comercio prueba 2",
                      price: 40000,
                      image: "award.png"
                    }
                  ]
                }
              ]
            }
            redemtionText="Redimir"
            termText="Aplican términos y condiciones."
            termLink="/terminos-y-condiciones"
          /> */}
      </Container>

      <Container>
        {
          context ?
            <Experience />
            :
            <Preloader
              type="section"
              invert
            />

        }
      </Container>
    </div>
  )
}
export default Awards