import React from 'react'
import Slider from "react-slick"

import CardBox from '../molecules/CardBox'
// import useWidth from '../../hooks/useWidth';

function SliderRotator({
  type,
  mobileItemsToShow,
  desktopItemsToShow,
  list,
  CenterMode = false,
  SamplePrevArrow,
  SampleNextArrow
}) {
// const width = useWidth()

  const settings = {
    centerMode: CenterMode ? true : false,
    className: CenterMode && "center",
    dots: true,
    nextArrow: SampleNextArrow && <SampleNextArrow />,
    prevArrow: SamplePrevArrow && <SamplePrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: desktopItemsToShow,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    centerPadding: '0px',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: mobileItemsToShow,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  }

  return (
    <div className={`mc-slider-rotator`}>
      <Slider {...settings}>
        {list.map((slide, index) => {

          return (
            <div key={index} className='mc-slider-rotator__slide'>
              <CardBox
                type={type}
                image={slide?.image}
                title={slide?.title}
                desc={slide?.desc}
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default SliderRotator