import React from 'react'

function CardBox({
  type,
  image,
  title,
  desc
}) {

  return (
    <div className={`mc-cardbox-container`}>
      {
        type === 1 &&

        <div className={`card-login_type_${type}`}>
          <div className="rowCard">
            <div className="imgStep">
              {image &&
                <img src={`/assets/images/mecanica/${image}`} alt="Step image" />
              }
            </div>
            <div className="card-login-description">
              <div className="title">
                {
                  title &&
                  <h4 className='color-green text-center'>{title}</h4>
                }
              </div>
              {desc &&
                <div className='text-center' dangerouslySetInnerHTML={{ __html: desc }}></div>
              }
            </div>
          </div>
        </div>

      }
      {
        type === 2 &&
        <div className={`card-login_type_${type}`}>
          <div className="rowCard">
            <div className="imgStep">
              {image &&
                <img src={`/assets/images/mecanica/${image}`} alt="Step image" />
              }
            </div>
            <div className="card-login-description">
              {desc &&
                <div dangerouslySetInnerHTML={{ __html: desc }}></div>
              }

            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default CardBox