import React from 'react'

function Icon(props) {

  return (
    <div className={`mc-icon`}>
      {props.name === "test" &&
        <span className={`icon-${props.name}`}>
          <img src={`/assets/images/${props.name}.png`} alt='Boton Test' />
        </span>
      }
      {props.name === "icon" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.103 2H14.897C15.2911 1.99995 15.6814 2.07756 16.0455 2.22838C16.4096 2.3792 16.7404 2.60029 17.019 2.879L21.12 6.979C21.3993 7.25791 21.6207 7.58917 21.7717 7.95382C21.9227 8.31846 22.0003 8.70932 22 9.104V14.898C22 15.2921 21.9224 15.6824 21.7716 16.0465C21.6208 16.4106 21.3997 16.7414 21.121 17.02L17.021 21.121C16.7423 21.4 16.4114 21.6214 16.0471 21.7724C15.6828 21.9233 15.2923 22.001 14.898 22.001H9.103C8.70883 22.0009 8.31854 21.9232 7.95443 21.7722C7.59033 21.6212 7.25955 21.3999 6.981 21.121L2.88 17.021C2.60087 16.7422 2.37946 16.4111 2.22846 16.0466C2.07746 15.6822 1.99982 15.2915 2 14.897V9.103C1.99995 8.7089 2.07756 8.31865 2.22838 7.95455C2.3792 7.59045 2.60029 7.25963 2.879 6.981L6.979 2.88C7.25791 2.60075 7.58917 2.37927 7.95382 2.22827C8.31846 2.07726 8.70932 1.99969 9.104 2H9.103Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 16V16.01" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 13C12.4498 13.0014 12.8868 12.8511 13.2407 12.5734C13.5945 12.2958 13.8444 11.907 13.95 11.4698C14.0557 11.0327 14.0109 10.5726 13.8229 10.1641C13.6349 9.75549 13.3147 9.42219 12.914 9.21801C12.5162 9.01422 12.0611 8.95103 11.6228 9.03873C11.1845 9.12642 10.7888 9.35984 10.5 9.70101" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      }
      {props.name === "close" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M1.31728 16.6346L0 15.3173L7 8.31728L0 1.31728L1.31728 0L8.31728 7L15.3173 0L16.6346 1.31728L9.63456 8.31728L16.6346 15.3173L15.3173 16.6346L8.31728 9.63456L1.31728 16.6346Z" fill="#2B3550" />
          </svg>
        </span>
      }
      {props.name === "faq" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12 6L8 10L4 6" stroke="#016836" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      }
      {props.name === "close_session" &&
        <span className={`icon-${props.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6.6665 5.33335V4.00002C6.6665 3.6464 6.80698 3.30726 7.05703 3.05721C7.30708 2.80716 7.64622 2.66669 7.99984 2.66669H12.6665C13.0201 2.66669 13.3593 2.80716 13.6093 3.05721C13.8594 3.30726 13.9998 3.6464 13.9998 4.00002V12C13.9998 12.3536 13.8594 12.6928 13.6093 12.9428C13.3593 13.1929 13.0201 13.3334 12.6665 13.3334H7.99984C7.64622 13.3334 7.30708 13.1929 7.05703 12.9428C6.80698 12.6928 6.6665 12.3536 6.6665 12V10.6667" stroke="#040303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 8H2L4 6" stroke="#040303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 10L2 8" stroke="#040303" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      }
      {props.name === "menu" &&
        <span className={`icon-${props.name}`}>
          <svg width="40" height="29" viewBox="0 0 40 29" fill="none">
            <rect y="0.5" width="40" height="4" fill="white" />
            <rect y="12.5" width="40" height="4" fill="white" />
            <rect y="24.5" width="40" height="4" fill="white" />
          </svg>
        </span>
      }





    </div>
  )
}

export default Icon