import React, { useState, useContext } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import CustomModal from './CustomModal'
import Button from '../atoms/Button'
import Tag from '../atoms/Tag'
import { getPrice, sendDataLayer } from '../../utils/functions'
import { doRedeem, doloadRedeem } from '../../utils/helpers'
import { StateController } from './../../App'

function AwardBox({
  type,
  award,
  canRedem,
  termLink,
  termText,
  redemtionText,
  showStock,
  token,
  download
}) {
  const { pages } = useContext(StateController)
  const [showConfirm, setShowConfirm] = useState(false)
  const [modalStep, setModalStep] = useState(1)
  const [redemtionLoading, setRedemtionLoading] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [showErrorDownload, setShowErrorDownload] = useState(false)
  const [quantumName, setQuantumName] = useState('')
  const [quantumDoc, setQuantumDoc] = useState('')

  const handlerModal = () => {


    if (download && !downloadLoading) {
      setDownloadLoading(true)

      doloadRedeem(token, award.id).then((data) => {
        console.log("result download:", data)
        sendDataLayer({
          datalayer: {
            event: "redownload",
            product_name: award?.name,
            product_price: award?.price

          }, repeat: true
        })
        setDownloadLoading(false)
        if (data && data.status === 402) {
          setShowErrorDownload(true)
        }

      }).catch(error => {
        console.log("ERROR DOWNLOAD REDENTION:", error)
        setDownloadLoading(false)
        
      })

    } else {
      if (canRedem) {
        setModalStep(1)
        setRedemtionLoading(false)
        setShowConfirm(!showConfirm)
      }
    }
  }

  const handlerRedemtion = () => {
    setRedemtionLoading(true)

    if (canRedem) {
      doRedeem(token, award?.price, award.id, quantumName, quantumDoc ).then((data) => {
        console.log("response redemtions:", data)

        if (data && data.status === 200 && data.file) {
          sendDataLayer({
            datalayer: {
              event: "redeem_bond",
              product_name: award?.name,
              product_price: award?.price,
              index: award?.id
            }, repeat: true
          })

          setTimeout(() => {
            window.location.href = pages
              ? pages.find((page) => page.sublevel).children.find((page) => page.page.includes('redenciones')).page
              : 'mis-redenciones'
          }, 5000)
        } else {
          console.log("Mostrar modal de redencion fallida!")
          setShowErrorDownload(true)
          setShowConfirm(!showConfirm)
          sendDataLayer({
            datalayer: {
              event: "exhausted_bond",
              product_name: award?.name,
              product_price: award?.price,
              index: award?.id
            }, repeat: true
          })

        }

      }).catch(error => {
        console.log("ERROR ON REDENTION:", error)
      })
    }

  }

  return (
    <div className='mc-awards-container__items--item__container'>

      <CustomModal
        title="Error en la redención"
        desc={'Ocurrió un error al obtener tu bono. Intentalo de nuevo más tarde.'}
        cta="Aceptar"
        image="image.png"
        show={showErrorDownload} setShowModal={setShowErrorDownload}
        step={4}
      />
      {
        showErrorDownload && <div>HOALAAAAAAAA</div>
      }

      {award.prepurchased && !download && <Tag text="Precompra" />}
      {showStock && <Tag position={"left"} text={`${award?.stock} Disponible${award?.stock > 1 ? 's' : ''}`} />}
      {type === 1 &&
        <>
          <div className="mc-awards-container__items--item__image">
            <img src={`/assets/images/galeria/${award?.image}`} alt={award?.name} />
          </div>
          <div className="mc-awards-container__items--item__info">
            <div className="mc-awards-container__items--item__name">Bono de</div>
            <div className="mc-awards-container__items--item__price pb-3">COP {getPrice(award?.price)}</div>
            {termLink &&
              <p>
                <Link to={termLink} target="_blank">
                  {termText}
                </Link>
              </p>
            }
            <Button
              type="primary"
              text={redemtionText}
              loading={downloadLoading}
              status={canRedem ? 'active' : 'inactive'}
              onClick={() => {
                handlerModal();
                sendDataLayer({
                  datalayer: {
                    event: "bond_interest",
                    product_name: award?.name,
                    product_price: award?.price,
                    index: award?.id
                  }, repeat: true
                })

              }}
            />
          </div>
        </>
      }
      {type === 2 &&
        <>
          <div className="mc-awards-container__items--item__info">
            <div
              className="mc-awards-container__items--item__image"
              style={{ "backgroundImage": `url(/assets/images/galeria/${award?.image})` }}
            ></div>
            <div className="mc-awards-container__items--item__info">
              <div className="mc-awards-container__items--item__name">{award?.name}</div>
              <div className="mc-awards-container__items--item__price">COP {award?.price}</div>
            </div>
          </div>
          {termLink &&
            <p>
              <a href={termLink} target='_blank' rel="noreferrer">
                {termText}
              </a>
            </p>
          }
          <Button
            type="primary"
            text={redemtionText}
            loading={false}
            status="active"
            onClick={(e) => handlerModal(e,)}
          />
        </>
      }
      {type === 3 &&
        <>
          <div className="mc-awards-container__items--item__info">
            <div
              className="mc-awards-container__items--item__image"
              style={{ "backgroundImage": `url(/assets/images/galeria/${award?.image})` }}
            ></div>
            <div className="mc-awards-container__items--item__labels">
              <div className="mc-awards-container__items--item__name">{award?.name}</div>
              <div className="mc-awards-container__items--item__price">COP {award?.price}</div>
            </div>
          </div>
          <div className='mc-awards-container__items--item__actions'>
            {termLink &&
              <p>
                <a href={termLink} target='_blank' rel="noreferrer">
                  {termText}
                </a>
              </p>
            }
            <Button
              type="primary"
              text={redemtionText}
              loading={false}
              status="active"
              onClick={(e) => handlerModal(e,)}
            />
          </div>
        </>
      }
      {type === 4 &&
        <>
          <div className="mc-awards-container__items--item__info">
            <div className="mc-awards-container__items--item__image">
              <span style={{ "backgroundImage": `url(/assets/images/galeria/${award?.image})` }}></span>
            </div>
            <div className="mc-awards-container__items--item__name">{award?.name}</div>
            <div className="mc-awards-container__items--item__price">COP {award?.price}</div>
            <Button
              type="primary"
              text={redemtionText}
              loading={false}
              status="active"
              onClick={(e) => handlerModal(e,)}
            />
            {termLink &&
              <p>
                <a href={termLink} target='_blank' rel="noreferrer">
                  {termText}
                </a>
              </p>
            }
          </div>
        </>
      }
  
      <CustomModal
        show={showConfirm}
        step={modalStep}
        setModalStep={setModalStep}
        setShowModal={setShowConfirm}
        image={award?.image}
        title={`¿Deseas redimir este premio?`}
        desc={award?.name}
        price={award?.price}
        id={award?.id}
        location={award?.location}
        bigDesc={award?.description}
        showErrorDownload
        setQuantumName={setQuantumName}
        setQuantumDoc={setQuantumDoc}
        quantumName={quantumName}
        quantumDoc={quantumDoc}
        primaryAction={
          {
            action: handlerRedemtion,
            buttonText: redemtionText,
            loading: redemtionLoading
          }
        }
        secondaryAction={
          {
            action: handlerModal,
            buttonText: "Cancelar"
          }
        }
        terms={
          {
            termsText: termText,
            termsLink: termLink
          }
        }
      />
    </div>
  )
}

const mapState = ({ user: { token } }) => {

  return {
    token
  }
}

export default connect(mapState)(AwardBox)