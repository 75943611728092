import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { StateController } from '../../App'
import { sendDataLayer } from '../../utils/functions'

const Experience = () => {
	const { context } = useContext(StateController)	
	const listExperiences = [
		{
			id: 1,			
			title:'<span>Un viaje a Santa Marta</span> para dos personas, una experiencia verdaderamente priceless.',
			img: 'ViajeSantaMarta.png'
		},
		{
			id: 2,
			title:'<span>Una cena gourmet</span> para dos personas, una experiencia verdaderamente priceless.',			
			img: 'gastronomica.png'
		},
		{
			id: 3,
			title:'<span>Un bono de tecnología</span> para comprar eso que tanto deseas.',			
			img: 'tecnologia.png'
		},
	]
	const  filterUser = listExperiences.filter(item => item.id == context?.user?.segment)	

	return (
		<div className='Experience my-5'>
			<div className="rowExperience">
				<div className="imgExperience">
					<img src={`/assets/images/experiencias/${filterUser[0]?.img}`} alt={`Experiencia de ${filterUser[0]?.title}`} />
				</div>
				<div className="contentExperience">
					<p className='pb-2 color-gray'>Sigue cumpliendo tu meta y podrás participar por:</p>
						<p dangerouslySetInnerHTML={{__html:filterUser[0]?.title}} className='color-gray pb-2 title-experience'></p>
						<Link onClick={(e) => sendDataLayer({datalayer:{event: "steps",text: e.target.textContent,link: window.location.href}, repeat:true})} to="/terminos-y-condiciones" className='color-green pb-2'>Ver términos y condiciones</Link>
				</div>
			</div>
		</div>
	)
}

export default Experience
