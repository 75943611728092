import React, { useContext, useEffect, useState } from 'react'

import Container from '../../layouts/Container/Container'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'
import { StateController } from './../../App'
import Columns, { Col } from '../../layouts/Columns/Columns'

function Redemptions() {
  const { context } = useContext(StateController)
  const [redemtions, setRedemtions] = useState(false)

  useEffect(() => {
    if (context && context.redemtions) {
      console.log("Context redemtions:", context)
      setRedemtions(context?.redemtions)
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className='mc-page redemptions page-bg slow_ani'>
      <section className='py-6'>
        <article>
          <Container>
            <Columns
              desktop={2}
              mobile={1}
            >
              <Col>
                <div>
                  <h2 className='size-48 color-green text-center'>Este es tu premio redimido</h2>
                  <p className='color-gray size-24 text-center'>Descárgalo nuevamente aquí cuando lo necesites.</p>
                </div>


                <div>
                  {redemtions
                    ? <AwardsGallery
                      awardBoxType={1}
                      itemsPerLine={1}
                      list={redemtions}
                      showStock={false}
                      canRedem={true}
                      download={true}
                      redemtionText="Volver a descargar"
                      termText="Términos y condiciones"
                      termLink="/terminos-y-condiciones"
                    />
                    : 
                    <>
                    <Preloader
                      type="section"
                      invert
                      />
                      <h4 className='text-center'>No tienes redenciones</h4>
                    </>
                  }
                </div>
              </Col>
              <Col>
                <div className='d-none d-lg-block'>
                  <img className='border-24' src="/assets/images/banner-redenciones.svg" alt="Banner de redenciones" />
                </div>
              </Col>
            </Columns>
          </Container>

        </article>
      </section>

    </div>
  )
}
export default Redemptions