import React, { useContext } from 'react'

import Container from '../../layouts/Container/Container'
// import AlliancesGallery from '../organisms/AlliancesGallery'
import Columns, { Col } from '../../layouts/Columns/Columns'
import CircleProgress from '../atoms/CircleProgress'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Button from '../atoms/Button'
import useWidth from '../../hooks/useWidth'
import Experience from '../molecules/Experience'
import AwardsBanner from '../molecules/AwardsBanner'
import { StateController } from '../../App'
import { getPrice, sendDataLayer } from '../../utils/functions'
import GoalCounter from '../atoms/GoalCounter'
import Preloader from '../atoms/Preloader'

function Progress() {
  const { context } = useContext(StateController)
  const width = useWidth()

  return (
    <div className='mc-page progress page-bg slow_ani'>
      <section className='border-bottom-64 py-5'>
        <article>
          <Container>
            <h2 className='color-green size-48 text-center pb-3'>Así va tu progreso</h2>
            <p className='size-24 color-gray text-center'>Canjea tu bono al cumplir tu meta de facturación. ¡Consulta tu progreso aquí mismo para mantenerte al tanto!</p>

            <div className="section-progress">
              <Columns
                desktop={2}
                mobile={1}
                height={200}
              >
                <Col CustomClass='text-start'>
                  {
                    context ?

                      <div className='mc-section'>
                        <div className='contentProgress'>
                          <p className='text-center color-gray size-20 font-bold'>Tu meta en facturación es de:</p>
                          <h4 className='size-40 color-green m-0 text-center'>{getPrice(context?.user?.goal_amount_1)}</h4>
                          <Columns
                            desktop={2}
                            mobile={2}>
                            <Col CustomClass={width.width < 992 ? 'text-center':'text-start'} className="circleProgreessContainer">
                              {
                                context ?
                                  <CircleProgress
                                    widthSvg={width.width > 992 ? 200 : 120}
                                    circleWidth={width.width > 992 ? 140 : 110}
                                    context={context}
                                    fontSize={28}
                                  />
                                  :
                                  <Preloader
                                    type="section"
                                    invert
                                  />

                              }
                            </Col>
                            <Col>
                              <p>Acumulado en compras:</p>
                              <h4 className='color-green size-32 m-0'>{getPrice(context.tracing ? context?.tracing?.amount_1 : 0) || 0}</h4>
                              <GoalCounter label="* Metas cumplidas:" rule="complete" />
                              <p className='size-12'>Información actualizada: {context?.tracing?.date_update || ''}</p>
                            </Col>
                          </Columns>
                          <Columns desktop={1} mobile={1}>
                            <Col CustomClass='text-center'>
                              <p className={`size-14 color-gray ${width?.width > 992 ? 'px-1' : 'px-2'}`}>
                                *Cumple tu meta varias veces y si eres el que más veces la cumple, podrás ganar un <b>premio especial.</b> Cada vez que cumplas tu meta, tu progresa se reiniciará.
                              </p>
                            </Col>
                            <Col>
                              {/* <p className='text-center font-bold py-2'>Completa tu meta y disfruta tu bono.</p> */}
                            </Col>
                            <div onClick={(e) => sendDataLayer({datalayer:{event: "progress",text: e.target.textContent,link: window.location.href}, repeat:true})} className='flex flex-justify-center py-2'>
                              <Button
                                type='primary'
                                text={'Conoce los premios'}
                                link={'/premios'}
                                status={'active'}

                              />
                            </div>
                          </Columns>
                        </div>
                      </div>
                      :
                      <Preloader
                        type="section"
                        invert
                      />
                  }

                </Col>
                <Col>
                  {
                    width?.width > 992 &&
                    <VerticalCenter>
                      <div>
                        <img src="/assets/images/login-banner.svg" alt="" />
                      </div>
                    </VerticalCenter>
                  }
                </Col>
              </Columns>

            </div>





          </Container>

        </article>

      </section>
      <section>
        <article>
          <Container>
            <div className='mc-section'>
              <div>
                {
                  context ?
                    <Experience />
                    :
                    <Preloader
                      type="section"
                      invert
                    />

                }
              </div>
            </div>

          </Container>
        </article>
      </section>

      {
        context &&
        <section>
          <article>
            <Container>
              <div className='text-center'>
                <h3 className='color-green size-32'>Canjea un bono de {getPrice(context?.user?.award_1)}</h3>
                <p className='color-gray size-24'>en cualquiera de estos comercios:</p>
              </div>
              <div className='my-3'>
                <AwardsBanner
                  slider={width.width < 992 && true}
                  title={<><h2>Canjea un bono de {getPrice(context?.user?.award_1)}</h2></>}
                />
              </div>
            </Container>
          </article>
        </section>
      }

      <section className='py-3'>
        <article>
          <Container>
            <div className='text-center'>
              <h3 className='color-green size-32'>¡Aprovecha los descuentos exclusivos!</h3>
              <p className='color-gray size-24'>Descubre las ofertas especiales que hemos preparado para ti y cumple tu meta
                más rápido con estas alianzas.</p>
              <div onClick={(e) => sendDataLayer({datalayer:{event: "progress",text: e.target.textContent,link: window.location.href}, repeat:true})} className='flex flex-justify-center py-4'>
                <Button
                  type={'primary'}
                  text={'Explorar descuentos'}
                  link={'/descuentos'}
                  status={'active'}
                  className={'fit-content'}
                />
              </div>
            </div>
          </Container>
        </article>
      </section>




      {/* <div className='mc-section'>
        <h2>Nuestros aliados</h2>
        <AlliancesGallery
          alliedBoxType={1}
          itemsPerLine={3}
          limit={3}
          limitButtonText="Ver todos los aliados"
          list={
            [
              {
                id: 1,
                name: "Comercio prueba",
                description: 'description...',
                rule: '30% OFF',
                image: "award.png"
              },
              {
                id: 1,
                name: "Comercio prueba",
                description: 'description...',
                rule: '30% OFF',
                image: "award.png"
              },
              {
                id: 1,
                name: "Comercio prueba",
                description: 'description...',
                rule: '30% OFF',
                image: "award.png"
              }
            ]
          }
          termText="Aplican términos y condiciones."
          termLink="/terminos-y-condiciones"
        />
      </div> */}

    </div>
  )
}
export default Progress