import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import AlliancesGallery from '../organisms/AlliancesGallery'
import Preloader from '../atoms/Preloader'
import Columns, { Col } from '../../layouts/Columns/Columns'
import SliderAlied from '../molecules/SliderAlied'
import useWidth from '../../hooks/useWidth'
import ArrowDto from '../molecules/ArrowDto'
import { sendDataLayer } from '../../utils/functions'
// import SliderAliedEDV from '../molecules/SliderAliedEDV'

function Alliances() {
  const [alliances, setAlliances] = useState(false)
  const [alliance2s, setAlliances2] = useState(false)
  const { context } = useContext(StateController)
  const [todayDate, setTodayDate] = useState('');

  useEffect(() => {
    if (context && context.alliances) {

      const formater = context.alliances.filter((item) => {
        if (item.activate) {
          if (item.activate.includes('/')) {
            const timer = new Date(item.activate)
            const today = new Date()
            if (today.toDateString() == timer.toDateString()) return true

          } else if (item.activate !== '') {
            const today = new Date()
            const days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']
            const todayDay = days[today.getDay()]
            const enableDays = item.activate.split(',')
            let returner = false
            setTodayDate(todayDay)

            for (let i = 0; i < enableDays.length; i++) {
              if (enableDays[i].toLowerCase() == todayDay.toLocaleLowerCase()) returner = true
            }
            return returner
          }
        } else {
          return true
        }
      }).map((item) => {        

        return {
          id: item.id,
          name: item.name,
          description: item.description,
          rule: `${item.rule}`,
          image: item.image,
          termsData: item.terms,
          subDescription: item.sub_description,
          link: item.link,
          banner: item.banner,
          link_file:item.link_file,
          img_rule:item.img_rule,
        }
      })

      setAlliances(formater)
    }
  }, [context])

  useEffect(() => {
    if (context && context.alliances) {

      const formarter2 = context.alliances.filter((item) => {
        
    
          if (item.activate) {
            if (item.activate.includes('/')) {
              const timer = new Date(item.activate)
              const today = new Date()
              if (today.toDateString() == timer.toDateString()) return true
  
            } else if (item.activate !== '') {
              const today = new Date()
              const days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado']
              const todayDay = days[today.getDay()]
              const enableDays = item.activate.split(',')
              let returner = false
              setTodayDate(todayDay)
  
              for (let i = 0; i < enableDays.length; i++) {
                if (enableDays[i].toLowerCase() == todayDay.toLocaleLowerCase()) returner = true
              }
              return returner
            }
          } else {
            return true
          }
        

      }).map((item) => {        

        return {
          id: item.id,
          name: item.name,
          description: item.description,
          rule: `${item.rule}`,
          image: item.image,
          termsData: item.terms,
          subDescription: item.sub_description,
          link: item.link,
          banner: item.banner,
          link_file:item.link_file,
          img_rule:item.img_rule,
        }
      })

      console.log("Alianzas para la semana:", formarter2)
      const ordenDeseado = [63, 64, 52, 9, 46, 47, 48, 53, 62, 40, 3, 1, 2, 36, 35, 34, 50, 7, 8,  15, 14, 16, 59,
        
         4, 5, 6, 11, 13, 33, 37, 38, 39, 42, 43, 44, 45,  51, 54, 55, 56, 57, 58,  60,
        
        41, 12
      ]
      const mapaOrden = {}
      console.log("Lengh:", ordenDeseado.length)

      ordenDeseado.forEach((id, index) => {
        mapaOrden[id] = index;
      })
      const arrayOrdenado = formarter2.sort((a, b) => mapaOrden[a.id] - mapaOrden[b.id]);

      console.log("sorted!:", arrayOrdenado)
      setAlliances2(arrayOrdenado)
    }
  }, [context])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
  const width = useWidth()
  const dataLayerAllied = (e,) => {
    sendDataLayer({ datalayer: { 
      event: "alliances", text: e.target.textContent, link: window.location.href
    }, repeat: true })
  }
  return (
    <div className='mc-page alliances page-bg page-bg slow_ani'>
      <section className={`pt-7 px-2`}>
        <article className={width.width < 992 && 'border-bottom-64 py-5'}>
          <Container>
            {
              width.width < 992 &&
              <div>
                <h2 className={`color-green size-38 pb-3 ${width.width < 992 && 'text-center'}`}>¡Aprovecha los <br className='d-none d-lg-block' /> descuentos exclusivos!</h2>
                <p className={`color-gray size-24 ${width.width < 992 && 'text-center'}`}>Descubre las ofertas especiales que hemos preparado para ti y cumple tu meta más rápido con estas alianzas.</p>
              </div>
            }
          </Container>
        </article>

        <article>
          <Container>
            <Columns

              desktop={2}
              mobile={1}>
              <Col>
                {
                  width.width > 992 &&
                  <div className={width.width < 992 && 'border-bottom-64 py-5'}>
                    <h2 className={`color-green size-38 pb-3 ${width.width < 992 && 'text-center'}`}>¡Aprovecha los <br className='d-none d-lg-block' /> descuentos exclusivos!</h2>
                    <p className={`color-gray size-24 ${width.width < 992 && 'text-center'}`}>Descubre las ofertas especiales que hemos preparado para ti y cumple tu meta más rápido con estas alianzas.</p>
                  </div>
                }
                <div className='pt-4'>
                  {
                    width.width > 992
                      ?
                      <ArrowDto todayDate={todayDate} />
                      :
                      <>
                        <h4 className='color-green size-24 text-center'>Descuentos</h4>
                        <p className='size-16 text-center'>para hoy {todayDate}</p>
                      </>
                  }
                </div>
              </Col>
              <Col className="alliances_container">
                <div className='py-4' style={{ width: width?.width > 992 ? '626px' : '300px', margin: '0 auto' }}>
                  <Container>
                    {alliances &&
                      <SliderAlied list={alliances} />
                    }
                    <div className={`flex flex-justify-start pt-4 alliances-actions`}>
                      <a onClick={dataLayerAllied} className='btn btn-primary w-50 custom-cta' href={`/assets/images/alianzas/SemanarioPopular.pdf`} target="_blank" rel="noopener noreferrer">
                      Ver alianzas de la semana
                      </a>
                    </div>
                  </Container>
                </div>

              </Col>
            </Columns>

          </Container>
        </article>
        <article>

        </article>
      </section>

      <section>
        <article className='py-6'>
          <Container>
            <h2 className='color-green text-center size-24'>Alianzas para la semana</h2>
          </Container>
          
          {/* <Container>
            {alliances &&
              <SliderAliedEDV list={alliances} />
            }
          </Container>         */}

          <Container>
            {alliance2s
              ? <AlliancesGallery
                alliedBoxType={1}
                itemsPerLine={3}
                limit={false}
                termsOnModal={true}
                limitButtonText="Ver todos los aliados"
                list={alliance2s}
                termText="Ver términos y condiciones"
                termLink="/terminos-y-condiciones"
              />
              : <Preloader
                type="section"
                invert
              />
            }
          </Container>
        </article>
      </section>

    </div>
  )
}
export default Alliances