import React, { useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import { StateController } from './../../App'

import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'
import Button from '../atoms/Button'
import Icon from '../atoms/Icon'
import { sendDataLayer } from '../../utils/functions'
import FormQuantumData from './FormQuantumData'

function CustomModal({
  show,
  setShowModal,
  primaryAction,
  secondaryAction,
  terms,
  image,
  title,
  desc,
  bigDesc,
  cta,
  step,
  setModalStep,
  price,
  id,
  location,
  setQuantumName,
  setQuantumDoc,
  quantumName,
  quantumDoc,
  footerCopy
}) {
  const { setMdalOpen } = useContext(StateController)

  useEffect(() => {

    setMdalOpen(show ? true : false)
  }, [show])
  const close = () => { 
    if (step === 3) {
      primaryAction?.action()
      
    }   
    sendDataLayer({
      datalayer:{
        event: "cancel_bond",
        product_name: desc,
        product_price: price,
        index: id

      },
      repeat:true
    })
    setShowModal(false)
  }

  const nextStep = () => {
    setModalStep(2)
  }
  
  return (
    <div className={`mc-custom-modal ${show && 'mc-custom-modal__opened'} slow_ani`}>
      <div className="mc-custom-modal__overlay slow_ani" onClick={close}></div>
      <div className={`mc-custom-modal__center step-${step}`}>
        <VerticalCenter>
          <div className={`mc-custom-modal__center--window slow_ani step-${step}`}>

            {step === 1 &&
               <>
                 <div className={`bg-modal-step-${step}`}>
                  <div className="mc-custom-modal__close" onClick={close}>
                      <Icon name="close" />
                    </div>
                    {image &&
                      <div
                        className="mc-custom-modal__center--window__image"
                        style={{"backgroundImage": `url(/assets/images/galeria/${image})`}}
                      >
                      </div>
                    }
                    <div className="mc-custom-modal__center--window__title">{desc}</div>
                    <div className="mc-custom-modal__center--window__desc">Términos y condiciones</div>
                    {/* <di>{bigDesc}</di> */}
                    <div>
                      <div className='bigDesc' dangerouslySetInnerHTML={{ __html: bigDesc }} />
                    </div>
                 </div>
                  <div className="mc-custom-modal__center--window__actions">
                    <Button
                      type="primary"
                      text="Continuar"
                      loading={false}
                      status="active"
                      onClick={nextStep}
                      className={'fit-content mx-auto'}
                    />
                  </div>
                </>
} 
              { step === 2 &&
                <>
                    <div className="mc-custom-modal__close" onClick={close}>
                      <Icon name="close" />
                    </div>
                  <div className={`bg-modal-step-${step} py-4 mt-4`}>
                    <div className="mc-custom-modal__center--window__title size-18 ">{title}</div>
                    {image &&
                      <div
                        className="mc-custom-modal__center--window__image"
                        style={{"backgroundImage": `url(/assets/images/galeria/${image})`}}
                      >
                      </div>
                    }
                    <div className="mc-custom-modal__center--window__desc">{desc}</div>
                  </div>
                  
                  {
                    location 
                    ?
                      <FormQuantumData 
                      setQuantumName={setQuantumName}
                      setQuantumDoc={setQuantumDoc}
                      quantumName={quantumName}
                      quantumDoc={quantumDoc}
                      location={location} />    
                    :
                    <></>           
                  }

                  <div className="mc-custom-modal__center--window__actions btnModal-2">
                    <div className='fill'>
                      <Button
                        type="primary"
                        text={primaryAction ? primaryAction.buttonText : cta}
                        loading={primaryAction ? primaryAction.loading : false}                        
                        status={`${location === 1 ? (quantumName.length > 3 && quantumDoc.length > 3) ? 'active' : 'inactive' : location === 2 ? 'inactive' : 'active'}`}
                        onClick={primaryAction ? primaryAction.action : close}
                        className={'fill'}
                      />
                    </div>
                    {secondaryAction &&
                     <div className='fill' onClick={close}>
                       <Button
                        type="secondary"
                        text={secondaryAction.buttonText}
                        loading={false}
                        status="active"
                        onClick={secondaryAction.action}  
                        className={'fill'}                              
                      />
                     </div>
                    }
                  </div>
                  {terms &&
                    <div className="mc-custom-modal__center--window__terms">
                      <Link to={terms.termsLink} target="_blank">
                        {terms.termsText}
                      </Link>
                    </div>
                  }
                </>
            }

            {
              step === 3 &&
              <>
              <div className="mc-custom-modal__close" onClick={close}>
                <Icon name="close" />
              </div>
            <div className={`bg-modal-step-${step} py-4 mt-4`}>
                <div className="mc-custom-modal__center--window__title size-18 ">{title}</div>

                <div className="mc-custom-modal__center--window__desc">{desc}</div>
              

              <div className="mc-custom-modal__center--window__actions btnModal-2">
                <div className='fill'>
                  <Button
                    type="primary"
                    text={primaryAction ? primaryAction.buttonText : cta}
                    loading={primaryAction ? primaryAction.loading : false}                        
                    status={`${location === 1 ? (quantumName.length > 3 && quantumDoc.length > 3) ? 'active' : 'inactive' : location === 2 ? 'inactive' : 'active'}`}
                    onClick={primaryAction ? primaryAction.action : close}
                    className={'fill'}
                  />
                </div>
                {secondaryAction &&
                <div className='fill' onClick={close}>
                  <Button
                    type="secondary"
                    text={secondaryAction.buttonText}
                    loading={false}
                    status="active"
                    onClick={secondaryAction.action}  
                    className={'fill'}                              
                  />
                </div>
                }
              </div>
              {terms &&
                <div className="mc-custom-modal__center--window__terms">
                  <Link to={terms.termsLink} target="_blank">
                    {terms.termsText}
                  </Link>
                </div>
              }
              {footerCopy && 
                <div>
                  {footerCopy}
                </div>
              }
            </div>
          </>


            }

            {
              step === 4 &&
              <>
              <div className="mc-custom-modal__close" onClick={close}>
                <Icon name="close" />
              </div>
            <div className={`bg-modal-step-${step} py-4 mt-4`}>
                <div className="mc-custom-modal__center--window__title size-18 ">{title}</div>

                <div className="mc-custom-modal__center--window__desc">{desc}</div>
              

              <div className="mc-custom-modal__center--window__actions btnModal-2">
                <div className='fill'>
                  <Button
                    type="primary"
                    text={primaryAction ? primaryAction.buttonText : cta}
                    loading={primaryAction ? primaryAction.loading : false}                        
                    status={`${location === 1 ? (quantumName.length > 3 && quantumDoc.length > 3) ? 'active' : 'inactive' : location === 2 ? 'inactive' : 'active'}`}
                    onClick={primaryAction ? primaryAction.action : close}
                    className={'fill'}
                  />
                </div>
                {secondaryAction &&
                <div className='fill' onClick={close}>
                  <Button
                    type="secondary"
                    text={secondaryAction.buttonText}
                    loading={false}
                    status="active"
                    onClick={secondaryAction.action}  
                    className={'fill'}                              
                  />
                </div>
                }
              </div>
              {terms &&
                <div className="mc-custom-modal__center--window__terms">
                  <Link to={terms.termsLink} target="_blank">
                    {terms.termsText}
                  </Link>
                </div>
              }
            </div>
          </>
            }

          </div>
        </VerticalCenter>
      </div>
    </div>
  )
}

export default CustomModal