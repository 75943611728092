import React, { useEffect, useState, createContext } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { connect } from 'react-redux'

// pages
import Login from "./components/pages/Login"
import Mechanic from "./components/pages/Mechanic"
import Progress from "./components/pages/Progress"
import Awards from "./components/pages/Awards"
import Redemptions from "./components/pages/Redemptions"
import Terms from "./components/pages/Terms"
import Faqs from "./components/pages/Faqs"
import Alliances from "./components/pages/Alliances"
import Dashboard from "./components/pages/Dashboard"
import NotFound from "./components/pages/NotFound"
// layout
// import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"

// components
import CustomModal from './components/molecules/CustomModal'

// actions
import {
  logOutAction,
  loginShaAction
} from './store/sessionDuck'

import {
  gtUser,
  gtStaticContent,
  doRedeem,
  gtPreRedeem
} from './utils/helpers'

import './styles/main.scss'
import useWidth from './hooks/useWidth'
import HeaderPopular from './layouts/Header/HeaderPopular'
import { getMaximumBlock, getWhateverPage, sendDataLayer } from './utils/functions'
import ProgressFloat from './components/molecules/ProgressFloat'
import Preloader from './components/atoms/Preloader'

export const StateController = createContext(null)

function App({
  error,
  token,
  loggedIn,
  logOutAction
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [modalOpen, setMdalOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [context, setContext] = useState(false)
  const [staticContent, setStaticContent] = useState(false)
  const [autologinPreloader, setAutologinPreloader] = useState(false)
  const home_page = '/mecanica'
  const [canRedem, setCanRedem] = useState(false)
  const [floatProgess, setFloatProgess] = useState(true);
  const [getRedemptions, setgetredemptions] = useState([])
  const [loading, setLoading] = useState(false);
  const [showErrorDownload, setShowErrorDownload] = useState(false)
  const [showConfirm, setShowConfirm] = useState(true)
  const [modalStep, setModalStep] = useState(3)
  const [preRedeem, setPreRedeem] = useState(false);
  
  
  const pages = [
    { page: 'mecanica', title: '¿Cómo ganar?', icon: 'home.svg', sublevel: false, component: <Mechanic showErrorDownload={showErrorDownload} setShowErrorDownload={setShowErrorDownload} privatePage /> },
    { page: 'progreso', title: 'Progreso', icon: 'progreso.svg', sublevel: false, component: <Progress privatePage /> },
    { page: 'premios', title: 'Premios', icon: 'premios.svg', sublevel: false, component: <Awards privatePage /> },
    { page: 'descuentos', title: 'Descuentos', icon: 'descuentos.svg', sublevel: false, component: <Alliances privatePage /> },
    {
      page: 'mas', title: 'Otras opciones', icon: 'opciones.svg', sublevel: true, children: [
        { page: 'mis-redenciones', title: 'Mi redención', icon: 'shopping.svg', component: <Redemptions privatePage /> },
        { page: 'terminos-y-condiciones', title: 'Términos y condiciones', icon: 'tyc.png', component: <Terms /> },
        { page: 'preguntas-frecuentes', title: 'Preguntas frecuentes', icon: 'faqs.png', component: <Faqs /> }
      ]
    }
  ]
  const closeLoginModal = () => {
    setAutologinPreloader(false)
    setNotFound(false)
    localStorage.removeItem('userNotFound')
    window.location.href = '/'
  }
  useEffect(() => {

    if (context && context.awards) {
      setCanRedem(
        context.awards[`block_${getMaximumBlock(context.block, context.blocks)}`].canRedem
      )
      if (canRedem) {
        sendDataLayer({ datalayer: { event: "goal" }, repeat: true })

      }
    }
  }, [context])

  useEffect(() => {
    if (localStorage.getItem('userNotFound')) {
      setNotFound(true)

      setTimeout(() => {
        localStorage.removeItem('userNotFound')
      }, 3000)
    } else {
      setNotFound(false)
    }
  }, [location])

  useEffect(() => {
    if (searchParams.get('utm_web')) {
      setAutologinPreloader(true)
    }

    if (!localStorage.getItem('storage')) {
      if (window.location.search !== '') {
        console.log("Navegate to:", window.location.search)
        // navigate(window.location.search)
      } else {
        const public_url = pages.find((page) => page.page === location.pathname.replace('/', ''))
        const public_children_urls = pages.find((page) => page.sublevel)

        if (public_url) {
          if (!loggedIn && public_url?.component?.props?.privatePage) document.location.href = '/'

        } else if (public_children_urls) {

          const public_child_url = public_children_urls?.children.find((childPage) => childPage.page === location.pathname.replace('/', ''))
          if (public_child_url?.component?.props?.privatePage) navigate('/')
        }
      }
    }
  }, [searchParams, error])

  useEffect(() => {

    if (token !== '') {

      gtUser(token).then((user) => {
        console.log('user', user)

        if (user?.data) {
          setContext(user.data)
          setgetredemptions(user?.data?.redemtions)
          
          if (window.location.pathname !== home_page && window.location.pathname === '/') {
            window.location.href = home_page
          }
          if (user?.data?.tracing && user?.data?.tracing[`winner_${user.data.block}`] === 1) {
            sendDataLayer({datalayer: { event: 'goal' },repeat: false})          
          }
        } else if (user.statusCode === 301 && localStorage.getItem('storage') && window.location.pathname === '/') {
          localStorage.removeItem('storage')
          window.location.reload()
        }
        if (user?.statusCode === 301 && window.location.pathname !== '/' && window.location.pathname !== '/dashboard') {
          window.location.href = '/'
        }
      }).catch(() => navigate('/'))

    }
  }, [token])

  useEffect(() => {

    gtStaticContent().then((content) => {
      if (content && content.content) setStaticContent(content.content)
    })
  }, [])


  useEffect(() =>{
    if (token !== '') {
      gtPreRedeem(token,60,20000,'','').then((preRed =>{
        setPreRedeem(preRed?.data?.data?.response)        
      })).catch( error =>{
        console.log("ERROR ON PRE-REDENTION:", error)
      })
    }
  },[token])
  
  const handlerRedemtion = () => {    
    setShowConfirm(false)
    setLoading(true)
      if ((token !== '' && context)) {

        doRedeem(token, 20000, 4, '', '',context?.tracing?.extra).then((data) => {
          console.log("response redemtions:", data)
          if (data && data.status === 200 && data.file) {
            setLoading(false)
            setTimeout(() =>{
              window.location.href = '/mis-redenciones'
            },4000)
  
          } else {
            console.log("Mostrar modal de redencion fallida!")
            setShowErrorDownload(true)
            setShowConfirm(!showConfirm)
            setLoading(false)
  
          }
  
        }).catch(error => {
          console.log("ERROR ON REDENTION:", error)
        })


      }
      return false


  }
    

  const width = useWidth()
  return (
    <StateController.Provider
      value={
        {
          setMdalOpen: setMdalOpen,
          context: context,
          staticContent: staticContent,
          pages: pages
        }
      }
    >


{
  (context?.tracing?.extra === '1' || context?.tracing?.extra === '2')  && (!getRedemptions?.some(redeem => redeem?.block === 10) && preRedeem?.message === 'Producto disponible') &&
  <CustomModal
  show={showConfirm}
  step={modalStep}
  setModalStep={setModalStep}
  setShowModal={setShowConfirm}
  image={'Crepes-Wafles.svg'}
  title={<><h2 className='color-green font-black line-28 size-24 size-24-md-20'>¡Felicidades, has <br className='d-none d-lg-block' /> ganado con tu Tarjeta!</h2></>}
  desc={<><p className='color-gray'>{
    context?.tracing?.extra === "2" ?
    <>
      Canjea ya tu bono en la sección <strong>“Premios”</strong> y encuentra tu bono adicional de Crepes & Waffles en la pestaña <strong>“Mi Redención”.</strong>      
    </>
    :
    <>
      Canjea ya tu bono de Crepes & Waffles en la sección <strong>“Mi Redención”.</strong>
    </>
  }</p></>}
  footerCopy={<><p className='size-12 font-bold text-center px-2'>
      {
        context?.tracing?.extra === "2" ? <>No olvides hacerlo antes del 31 de agosto de 2024, o hasta agotar existencias</> : <>Asegúrate de hacerlo antes del 31 de agosto de 2024, hasta agotar la bolsa de premios. Se entregarán 500 bonos a los primeros 500 clientes que rediman su bono.</>
      }
    </p></>}
  price={context?.tracing?.extra}
  id={23}      
  // bigDesc={award?.description}
  showErrorDownload
  // setQuantumName={setQuantumName}
  // setQuantumDoc={setQuantumDoc}
  // quantumName={quantumName}
  // quantumDoc={quantumDoc}
  primaryAction={
    {
      action: handlerRedemtion,
      buttonText: 'Redimir',
      // loading: redemtionLoading
    }
  }
  // secondaryAction={
  //   {
  //     action: handlerModal,
  //     buttonText: "Cancelar"
  //   }
  // }
  // terms={
  //   {
  //     termsText: termText,
  //     termsLink: termLink
  //   }
  // }
/>
}
{
  loading && <Preloader type={'fullscreen'} invert />
}

<CustomModal
        title="Datos incorrectos"
        desc="Verifica tu información y vuelve a intentarlo."
        cta="Aceptar"
        icon={false}
        primaryAction={
          {
            action: closeLoginModal,
            buttonText: 'Cerrar',
            loading: false
          }
        }
        show={notFound} setShowModal={setNotFound}
      />
      <div className={`App ${modalOpen ? 'modalOpen' : ''} ${loggedIn ? 'loggedIn' : 'noLoggedIn'} ${location.pathname === '/' ? 'login' : location.pathname.replace('/','')}`}>
        {/* <Header
          headerType={1}
          logo={ width?.width <= 992 ? 'banco_popular_MC.svg':'banco_popular.svg'}
          items={pages}
          // submenu={{
          //   type: "nickname",
          //   default: "A"
          // }}
          // notifications={
          //   [
          //     {
          //       id: 1,
          //       title: "Notification",
          //       content: "html content",
          //       status: 0
          //     }
          //   ]
          // }
          loggedIn={loggedIn}
          logOutAction={logOutAction}
        /> */}
        <HeaderPopular
          logo={width?.width <= 992 ? 'banco_popular_MC.svg' : 'banco_popular.svg'}
          loggedIn={loggedIn}
          logOutAction={logOutAction}
          pages={pages}
          floatProgess={floatProgess}
          setFloatProgess={setFloatProgess}
        />
        {
          (loggedIn && window.location.pathname !== '/progreso' && window.location.pathname !== '/dashboard' && window.location.pathname !== '/' && floatProgess) && <ProgressFloat setFloatProgess={setFloatProgess} context={context} />
        }

        <Routes>
          <Route path="/" element={<Login staticContent={staticContent} autologin={autologinPreloader} hash={searchParams.get('utm_web')} />} />

          {/* Root pages */}
          {pages.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}

          {/* Child pages */}
          {pages.find((page) => {
            return page.sublevel ? true : false
          })?.children.map((page, i) => {
            return (
              <Route
                key={i}
                path={`/${page.page}`}
                element={page.component}
              />
            )
          })}
          <Route path="/dashboard" element={<Dashboard adminPage />} />
          {getWhateverPage(location, pages) &&
            <Route path={`${getWhateverPage(location, pages)}`} element={<NotFound />} />
          }
        </Routes>

        <Footer
          footerType={1}
          footerCardsImg="Tarjetas.png"
          secondaryFooterImg="Logos.png"
          bankFooterImg="bank-footer.png"
          // links={
          //   [
          //     {
          //       link: "/terminos-y-condiciones",
          //       text: "Términos y condiciones"
          //     },
          //     {
          //       link: "/preguntas-frecuentes",
          //       text: "Preguntas frecuentes"
          //     }
          //   ]
          // }
          // linksText="Válido del XX de xxxxxxxx de xxxx al XX de xxxxxx de xxxx."
          content="<p>*Vigencia del 07 de junio al 07 de agosto de 2024 y sólo aplica para territorio colombiano. Recuerde que por su seguridad Banco Popular nunca solicita a través de este medio información confidencial o financiera como usuarios y claves de acceso a nuestros canales, ni números de productos como cuentas, números de tarjetas de crédito o similares.</p> <p>El diseño de los círculos de Mastercard es una marca registrada de Mastercard International Incorporated.</p>"
          copyright="© Copyright 2024"
        />

      </div>
    </StateController.Provider>
  )
}

const mapState = ({ user: { fetching, loggedIn, token, error } }) => {
  return {
    fetching,
    loggedIn,
    token,
    error
  }
}

export default connect(mapState, { logOutAction, loginShaAction })(App)