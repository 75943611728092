import React from 'react'
import { Link } from "react-router-dom"
import Slider from "react-slick"

import AwardBox from '../molecules/AwardBox'

function AwardsGallery({
  awardBoxType,
  itemsPerLine,
  limit,
  limitButtonText,
  list,
  canRedem,
  termText,
  termLink,
  termModal,
  redemtionText,
  steps,
  showStock,
  download
}) {
  
  const stepper_settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    customPaging: function(i) {
      return (
        <span key={i}>
          <p>{steps[i].name}</p>
        </span>
      );
    }
  }

  return (
    <div className={`mc-awards-container mc-awards-column-${itemsPerLine}`}>
        
      {list && list[0] 
        ? <div className="mc-awards-container__items">
            {list.filter((item, i) => {

              return limit ? i < limit ? true : false : true
            }).map((item, index) => {

              if (download && item.prepurchased === 1) item.prepurchased = true
              if (download && item.prepurchased === 0) item.prepurchased = false

              return (
                <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                  <AwardBox
                    type={awardBoxType}
                    award={item}
                    canRedem={canRedem}
                    // termLink={termLink}
                    // termText={termText}
                    // termModal={termModal}
                    redemtionText={redemtionText}
                    showStock={showStock}
                    download={download}
                  />
                </div>
              )
            })}
          </div>
        : <>No hay premios</>
      }

      {steps &&
        <div className="mc-awards-container__steper">

          <Slider {...stepper_settings}>
              {steps.map((step, index) => {

                return (
                  <div className="mc-awards-container__items" key={index}>
                    {step.awards.map((item, index) => {
                      
                      return (
                        <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                          <AwardBox
                            type={awardBoxType}
                            award={item}
                            termLink={termLink}
                            termText={termText}
                            termModal={termModal}
                            redemtionText={redemtionText}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
          </Slider>


        </div>
      }

      {limit && limitButtonText && (list && list[0]) && 
        <div className="mc-awards-container__actions">
          <Link to="/premios">{limitButtonText}</Link>
        </div>
      }
    </div>
  )
}

export default AwardsGallery