import React from 'react'

import Container from '../../layouts/Container/Container'
import LoginForm from '../organisms/LoginForm'
import useWidth from '../../hooks/useWidth'
import SliderRotator from '../organisms/SliderRotator'
import AwardsBanner from '../molecules/AwardsBanner'
import CounterDown from '../atoms/CounterDown'
import { getDateNow } from '../../utils/functions'

function Login({
  autologin,
  hash,
  staticContent
}) {
  const width = useWidth()
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (

      <img
        className={className}
        style={{ ...style, display: "block", top: '113%', right: width?.width > 1200 ? '70%' : '20%', zIndex: '100' }}
        onClick={onClick}
        src="/assets/images/mecanica/simple_arrow_right.svg" alt="" />

    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{ ...style, display: "block", top: '113%', left: width?.width > 1200 ? '1%' : '20%', zIndex: '100' }}
        onClick={onClick}
        src="/assets/images/mecanica/simple_arrow_left.svg" alt="" />
    );
  }
  const list = [
    {
      id: 1,
      image: "1.svg",
      title: "Title Card",
      desc: "<p><strong class='color-green'>Utiliza tu Tarjeta de Crédito Mastercard</strong> para todas tus transacciones diarias.</p>"
    },
    {
      id: 2,
      image: "2.svg",
      title: "Title Card",
      desc: "<p>Cumple tu meta de facturación y <strong class='color-green2'>gana un bono en el comercio que prefieras.</strong></p>"
    },
    {
      id: 3,
      image: "3.svg",
      title: "Title Card",
      desc: "<p><strong class='color-green2'>Elige tu bono favorito,</strong> completa tus datos, descárgalo y disfrútalo eso que tanto te gusta.</p>"
    },
    {
      id: 4,
      image: "4.svg",
      title: "Title Card",
      desc: "<p>Y si eres el cliente que más veces cumple su meta, <strong class='color-green2'>te llevarás el premio especial.</strong></p>"
    }

  ]

  return (
    <div className='mc-page login slow_ani loginHome page-bg'>

      <Container>
        <div className="customRow">
          <div className="colLeft">
            <div className="bgCol">
            <h1 className='size-32 color-green text-center font-black mb-4 mt-0'>
              {
                getDateNow() >= '2024-08-08'
                ?
                <>¡Compra y Gana ha finalizado!</>
                :
                <>¡Compra y gana está <br /> por terminar!</>
              }
            </h1>
              <div className="login-description">

                {
                getDateNow() >= '2024-08-08'
                ?
                <p className={'yellow-line-login text-center size-20'}>
                ¡Gracias por participar en nuestra campaña! <strong>Si aún no has redimido tu bono, hazlo antes del:</strong>
                </p>
                :
                <p className={'yellow-line-login text-center font-bold size-20'}>
                  <strong className='font-black'>Ingresa ya</strong> y descubre <br /> los premios que puedes <strong className='font-black'>GANAR</strong>
                </p>
                  
              }
              </div>

              <div className={`countdown-login mt-4 text-center ${width?.width > 992 ? 'mb-4' : ''}`}>
                {
                  getDateNow() < '2024-08-08' &&
                  <p className='text-center pb-1'>Solo quedan:</p>
                }
                <CounterDown
                  showDays
                  showHours
                  showMins
                  showSegs
                  staticContent={staticContent}
                />
              </div>
              {
                width?.width > 992 &&
                <div className="login-slider">
                  <h4 className='color-green2 size-20 font-bold m-0 pt-2'>¿Cómo ganar un bono?</h4>
                  <p  className='pb-2'>
                    Sigue estos pasos, participa y empieza a disfrutar.
                  </p>
                  <div>
                    {/* <h1 className='size-20'>¿Cómo ganar?</h1> */}

                    <SliderRotator
                      type={2}
                      mobileItemsToShow={1}
                      desktopItemsToShow={width?.width <= 1200 ? 1 : 2}
                      CenterMode={false}
                      SampleNextArrow={SampleNextArrow}
                      SamplePrevArrow={SamplePrevArrow}
                      list={list}
                    />
                  </div>
                </div>
              }
            </div>
          </div>

          <div className="colright">
            <div className="form pt-4">
              <div className="login-banner text-start">
                <img src="/assets/images/login-banner.svg" alt="" />
              </div>

              <LoginForm
                title="Título corto"
                type="credentials"
                fields={[
                  { field: "emailPhone", label: "Correo electrónico o número celular", placeholder: "Ingresa aquí tus datos", maxlength: 1000, separated: false }, { field: "document", label: "Últimos 6 dígitos de tu Tarjeta de Crédito", placeholder: "Ingresa aquí tus datos", maxlength: 6 }, { field: "terms", label: "He leído y acepto los", termsText: "términos y condiciones", termsLink: "/terminos-y-condiciones" } // DO NOT CHANGE THIS LINE
                ]}
                buttonText="Ingresar"
                background="#fff"
                autologin={autologin}
                hash={hash}
              />

            </div>
            {
              width?.width < 992 &&
              <div className="login-slider pt-5">
                <h4 className='color-green pb-2 m-0 pt-3 text-center size-24'>¿Cómo ganar un bono?</h4>
                <p className='text-center px-5'>
                  Sigue estos pasos, participa y empieza a disfrutar.
                </p>
                <div>
                  <SliderRotator
                    type={2}
                    mobileItemsToShow={1}
                    desktopItemsToShow={width?.width <= 1200 ? 1 : 2}
                    CenterMode={false}
                    SampleNextArrow={SampleNextArrow}
                    SamplePrevArrow={SamplePrevArrow}
                    list={list}
                  />
                </div>
              </div>
            }

          </div>
        </div>

        <div className='my-5'>
          <div>
            <h2 className='text-center size-32 color-green'>Podrás ganar un bono de:</h2>
          </div>
          <div className='py-3'>
          <AwardsBanner />
          </div>
        </div>
      </Container>
      {
        width?.width < 992 &&
        <Container>
          <div className='text-center py-3'>
            <img width={width?.width < 576 && '80%'} src="/assets/images/login-banner-mobile.png" alt="" className='border-radius'/>
          </div>
        </Container>
      }

    </div>
  )
}
export default Login