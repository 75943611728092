import React, { useState } from 'react'
import Container from '../Container/Container'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Dropdown from '../../components/molecules/Dropdown'
import { sendDataLayer } from '../../utils/functions'

const HeaderPopular = ({
    logo,
    pages,
    loggedIn,
    logOutAction,
    floatProgess,
    setFloatProgess
}) => {
    const [showSubmenu, setShowSubmenu] = useState(false)
    const dataLayerMenu = (e) =>{        
        sendDataLayer({datalayer:{
            event: "menu",
            text: e.target.textContent,
            link: window.location.href

        }, repeat:true})
    }
    return (
        <header className='mainHeaderPopular'>
            <Container>
                <div className="contentHeader">
                    <div className="logo">
                        <Link to={loggedIn ? '/mecanica' : '/'} >
                            <img src={`/assets/images/${logo}`} alt={'Logo del Banco Popular'} />
                        </Link>
                    </div>
                    {
                        loggedIn ?

                            <div className="navigation">
                                {!floatProgess &&
                                    <button onClick={() => setFloatProgess(!floatProgess)} className={`btnProgressFloat-header`}><img src="/assets/images/upFloat.svg" alt="" /></button>
                                
                                }
                                <nav>
                                    <ul className='ulHeader'>
                                        {
                                            pages ?
                                                pages.map((item, i) => (
                                                    <>
                                                        <li onClick={(e) => {
                                                            item?.title === 'Otras opciones' ? setShowSubmenu(!showSubmenu) : setShowSubmenu(false);
                                                            dataLayerMenu(e)
                                                        }} key={i} className='item-nav position-relative'>
                                                            {
                                                                item?.page === 'mas' ?
                                                                    <div className='link-nav cursor-pointer'>
                                                                        <span>
                                                                            <picture className="icon-nav">
                                                                                <img src={`/assets/images/header/${item?.icon}`} alt={`Icono de ${item?.title}`} />
                                                                            </picture>
                                                                            <div className="NavText">
                                                                                {item?.title}
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                    :
                                                                    <NavLink onClick={(e) => dataLayerMenu(e)} to={`/${item?.page}`} className='link-nav'>
                                                                        <span>
                                                                            <picture className="icon-nav">
                                                                                <img src={`/assets/images/header/${item?.icon}`} alt={`Icono de ${item?.title}`} />
                                                                            </picture>
                                                                            <div className="NavText">
                                                                                {item?.title}
                                                                            </div>
                                                                        </span>
                                                                    </NavLink>
                                                            }
                                                            {
                                                                item?.sublevel &&
                                                                <>
                                                                    {
                                                                        showSubmenu &&
                                                                        <Dropdown
                                                                            pages={item?.children}
                                                                            logOutAction={logOutAction}
                                                                            setShowSubmenu={setShowSubmenu}
                                                                        />
                                                                    }
                                                                </>


                                                            }
                                                        </li>
                                                    </>
                                                ))
                                                :
                                                <></>
                                        }
                                        <li className='item-nav logoMC'>
                                            <span>
                                                <img src="/assets/images/logosMC.svg" alt="" />
                                            </span>
                                        </li>

                                    </ul>
                                </nav>
                            </div>
                            :
                            <div className='logoMC'>
                                <span>
                                    <img src="/assets/images/logosMC.svg" alt="" />
                                </span>
                            </div>
                    }
                </div>
            </Container>
        </header>
    )
}

export default HeaderPopular
