import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'
import Preloader from './Preloader'
import { getDateNow } from '../../utils/functions'

function CounterDown({
  showDays,
  showHours,
  showMins,
  showSegs,
  staticContent
}) {
  const {context} = useContext(StateController)
  const [counterDays, setCounterDays] = useState(0)
  const [counterHours, setCounterHours] = useState(0)
  const [counterMins, setCounterMins] = useState(0)
  const [counterSegs, setCounterSegs] = useState(0)
  const [end, setEnd] = useState(false)    
  
  useEffect(() => {
    if (context && context.counter && context.counter.value != null) {
      const countDownDate = new Date(context.counter.value).getTime()

      const startCountdown = () => {
        const timer = setInterval(function() {
          let now = new Date().getTime();
          let distance = countDownDate - now;
            
          if (distance < 0) {
            setEnd(true)
            clearInterval(timer)
          }

          let days = Math.floor(distance / (1000 * 60 * 60 * 24));
          let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
          setCounterDays(days)
          setCounterHours(hours)
          setCounterMins(minutes)
          setCounterSegs(seconds)
        }, 1000)
      }
      startCountdown()
    }else if(staticContent){

      const countDownDate = new Date(getDateNow() >= '2024-08-08' ? '2024-08-31 23:59:59' : staticContent?.counter?.value).getTime()

      const startCountdown = () => {
        const timer = setInterval(function() {
          let now = new Date().getTime();
          let distance = countDownDate - now;
            
          if (distance < 0) {
            setEnd(true)
            clearInterval(timer)
          }

          let days = Math.floor(distance / (1000 * 60 * 60 * 24));
          let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
          setCounterDays(days)
          setCounterHours(hours)
          setCounterMins(minutes)
          setCounterSegs(seconds)
        }, 1000)
      }
      startCountdown()
      
    }
  }, [staticContent])

  return (
    <div className={`mc-counter-down`}>
      <div>
        {context
          ? <div id="countdown">
              {end
                ? <>El contador finalizó</>
                : <ul>
                    {showDays && <li><span id="days">{counterDays}</span>Días</li>}
                    {showHours && <li><span id="hours">{counterHours}</span>Horas</li>}
                    {showMins && <li><span id="minutes">{counterMins}</span>Minutos</li>}
                    {showSegs && <li><span id="seconds">{counterSegs}</span>Segundos</li>}
                  </ul>
              }
              
            </div>
          : 

          staticContent ?
              end 
              ?
              <ul>
              {showDays && 
              <>
              <li><span id="days">{'00'}</span>Días</li>
              <span className='separate'>|</span>
              
              </>
              }
              {showHours && 
              <>
              <li><span id="hours">{'00'}</span>Horas</li>
              <span className='separate'>|</span>
              
              </>
              }
              {showMins && 
              <>
              <li><span id="minutes">{'00'}</span>Minutos</li>
              <span className='separate'>|</span>
              
              </>
              }
              {showSegs && 
              <>
              <li><span id="seconds">{'00'}</span>Segundos</li>                    
              
              </>
              }
    </ul>
              :
            <ul>
                      {showDays && 
                      <>
                      <li><span id="days">{counterDays < 10 ? `0${counterDays}`: counterDays}</span>Días</li>
                      <span className='separate'>|</span>
                      
                      </>
                      }
                      {showHours && 
                      <>
                      <li><span id="hours">{counterHours < 10 ? `0${counterHours}`: counterHours}</span>Horas</li>
                      <span className='separate'>|</span>
                      
                      </>
                      }
                      {showMins && 
                      <>
                      <li><span id="minutes">{counterMins < 10 ? `0${counterMins}`: counterMins}</span>Minutos</li>
                      <span className='separate'>|</span>
                      
                      </>
                      }
                      {showSegs && 
                      <>
                      <li><span id="seconds">{counterSegs < 10 ? `0${counterSegs}`: counterSegs}</span>Segundos</li>                    
                      
                      </>
                      }
            </ul>
          :
          <Preloader
              type="section"
              invert
            />
        }
      </div>
    </div>
  )
}

export default CounterDown