import React from 'react'
import { Link } from "react-router-dom"

import Container from '../Container/Container'
import { sendDataLayer } from '../../utils/functions'

function Footer({
  footerType,
  footerCardsImg,
  secondaryFooterImg,
  links,
  linksText,
  content,
  bankFooterImg,
  copyright
}) {
  const dataLayerooter = (e) =>{
    sendDataLayer({
      datalayer: {
        event: "footer",
        text: e.target.textContent,
        link: window.location.href                              
      },repeat: true}) 
  }

  return (
    <div className={`mc-footer-container mc-footer-container-type-${footerType} mt-5`}>
      {footerType === 1 && footerCardsImg &&
        <img src={`/assets/images/${footerCardsImg}`} alt="Footer Cards" className='mc-footer-container__cards' />
      }
      {footerType === 1 && 
        <div className='mc-footer-container__bg'>
          <Container>
            <div className='mc-footer-container__section'>
              {links && links[0] &&
                <ul className='mc-footer-container__section--links'>
                  {links.map((link, index) => {
                    return (
                      <Link 
                        onClick={(e) =>{
                          sendDataLayer({datalayer: {event: 'footer',category: `footer - ${e.target.tagName} - ${e.target.textContent}`,action: 'clic',label: 'footer'},repeat: true})                          
                        }}
                        key={index}
                        to={link.link} 
                      >{link.text}</Link>
                    )
                  })}
                </ul>
              }
              {linksText && <p>{linksText}</p>}
              {secondaryFooterImg &&
                <img 
                src={`/assets/images/${secondaryFooterImg}`} 
                alt="Footer" 
                className='mc-footer-container__image'
                />
              }
                <Container>
                  <ul className='items-contacts'>
                    <li onClick={dataLayerooter }><img src="/assets/images/btnLineaVerde.svg" alt="Linea verde del Banco Popular" /></li>
                    <li onClick={dataLayerooter }><a href="tel:(601)7434646">Bogotá (601) 743 46 46</a></li>
                    <li onClick={dataLayerooter }><a href="tel:018000184646">Línea Nacional 01 8000 184646</a></li>
                  </ul>
                </Container>
            </div>
          </Container>
            <Container className="mc-footer-container__text">
              <div dangerouslySetInnerHTML={{ __html: content }} />
              <img
                src="/assets/images/vigilated.svg"
                className="mc-footer-vigilated" alt="Footer logos"
              />
            </Container>
        </div>
      }

      {/* {footerType === 1 &&
        <div className='mc-footer-container__subbg'>
          <Container>
            <img src={`/assets/images/${bankFooterImg}`} alt="Banco Footer" />
          </Container>
        </div>
      } */}

      {footerType === 2 && bankFooterImg &&
        <div className='mc-footer-container__bg'>
          <Container>
            <img 
              src={`/assets/images/${bankFooterImg}`} 
              alt="Banco Footer" 
              className='mc-footer-container__bg--image'
            />
            <div className='mc-footer-container__sections'>
              <div className='mc-footer-container__sections--section'>
                <h4>Información:</h4>
                {links && links[0] &&
                  <ul className='mc-footer-container__section--links'>
                    {links.map((link, index) => {
                      return (
                        <Link 
                          key={index}
                          to={link.link} 
                        >{link.text}</Link>
                      )
                    })}
                  </ul>
                }
              </div>
              <div className='mc-footer-container__sections--section'>
                <h4>Contacto:</h4>
                <p>xxxxxxxxxxxx</p>
              </div>
            </div>

            {secondaryFooterImg &&
              <img 
                src={`/assets/images/${secondaryFooterImg}`} 
                alt="Footer" 
                className='mc-footer-container__image'
              />
            }
          </Container>

          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <img
              src="/assets/images/vigilated.png"
              className="mc-footer-vigilated" alt="Footer logos"
            />
          </Container>
        </div> 
      } 

      {footerType === 3 && 
        <div className='mc-footer-container__bg'>
          {footerCardsImg &&
            <img src={`/assets/images/${footerCardsImg}`} alt="Footer Cards" className='mc-footer-container__cards' />
          }

          <Container>
            <img 
              src={`/assets/images/${bankFooterImg}`} 
              alt="Banco Footer" 
              className='mc-footer-container__bg--image'
            />

            <div className='mc-footer-container__section'>
              {links && links[0] &&
                <ul className='mc-footer-container__section--links'>
                  {links.map((link, index) => {
                    return (
                      <Link 
                        key={index}
                        to={link.link} 
                      >{link.text}</Link>
                    )
                  })}
                </ul>
              }
              {secondaryFooterImg &&
                <img 
                  src={`/assets/images/${secondaryFooterImg}`} 
                  alt="Footer" 
                  className='mc-footer-container__image'
                />
              }
            </div>
          </Container>

          <Container className="mc-footer-container__text">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <img
              src="/assets/images/vigilated.png"
              className="mc-footer-vigilated" alt="Footer logos"
            />
          </Container>

          <Container>
            <div className='mc-footer-container__subbg'>
              {copyright}
            </div>
          </Container>
            
        </div> 
      }

      {footerType === 4 && 
        <Container>
          <div className='mc-footer-container__section'>
            {links && links[0] &&
              <ul className='mc-footer-container__section--links'>
                {links.map((link, index) => {
                  return (
                    <Link 
                      key={index}
                      to={link.link} 
                    >{link.text}</Link>
                  )
                })}
              </ul>
            }
            {secondaryFooterImg &&
              <img 
                src={`/assets/images/${secondaryFooterImg}`} 
                alt="Footer" 
                className='mc-footer-container__image'
              />
            }
          </div>
        </Container>
      }

      {footerType === 4 && 
        <Container className="mc-footer-container__text">
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <img
            src="/assets/images/vigilated.png"
            className="mc-footer-vigilated" alt="Footer logos"
          />

          <img 
            src={`/assets/images/${bankFooterImg}`} 
            alt="Banco Footer" 
            className='mc-footer-container__bg--image'
          />
        </Container>
      }
    </div>
  )
}

export default Footer