import React from 'react'
import CircleProgress from '../atoms/CircleProgress'
// import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useWidth from '../../hooks/useWidth'
import Container from '../../layouts/Container/Container'
import VerticalCenter from '../../layouts/VerticalCenter/VerticalCenter'

const ProgressFloat = ({ context, setFloatProgess }) => {
	const width = useWidth()	

	return (
		<div className='ProgressFloat'>
			<Container fullHeight>

				<div className="barProgress">
					<CircleProgress
						// circleWidth={120}
						// widthSvg={60}
						circleWidth={width.width < 992 ? 108 : 110}
						widthSvg={width.width < 992 ? 80 : 60}
						fontSize={31}
						context={context}

					/>
				</div>

				<VerticalCenter>
					<span>Así va tu progreso.</span> Continúa participando por más premios.  <Link className='ProgressFloat_link' to='/progreso'>Ver más.</Link>

					<button className='btnProgressFloat' onClick={() => setFloatProgess(false)}>
						X
						<img src="/assets/images/downFloat.svg" alt="" />
					</button>
				</VerticalCenter>
			</Container>
		</div>
	)
}

export default ProgressFloat
