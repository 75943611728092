import { sendDataLayer } from '../../utils/functions'
import React, { useState } from 'react'
import CustomModal from './CustomModal'

function AlliedBox({
  type,
  allied,
  termLink,
  termText,
  termsOnModal,
  termsData,
  customClass,
  textBTN,
}) {
  const [showOnModal, setShowOnModal] = useState(false)
  const dataLayerAllied = (e, content) => {
    sendDataLayer({ datalayer: { 
      event: "alliances", text: content?.name, link: window.location.href
    }, repeat: true })
  }
  const modalToggler = () => {
    setShowOnModal(!showOnModal)
  }

  return (
    <div className={`mc-alliances-container__items--item__container ${termsOnModal ? 'terms' : ''} ${customClass ? customClass : ''} ${allied.subDescription === 'edv' ? 'alliedEDV' : ''}`}>
      {type === 1 &&
        <>
          <div className="mc-alliances-container__items--item__image">
            <div className="dto-logo">
              <img className='img_dto' src={`/assets/images/alianzas/dto/${allied?.img_rule}`} alt="" />
              <img className='img_logo' src={`/assets/images/alianzas/${(allied.subDescription === 'everyDay') ? allied?.termsData : allied?.image }`} alt="" />


            </div>
          </div>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__title">{(allied?.description?.includes('física') && !allied?.link) ? allied?.name : <><a onClick={(e) => dataLayerAllied(e,allied)} className='link_title' href={allied?.link} target="_blank" rel="noopener noreferrer">{allied?.name}</a></>}</div>
            <div className="inf_dto">
              <div className='size-16 font-bold color-gray3 rule-allied' dangerouslySetInnerHTML={{ __html: allied?.rule }} />
              <div className="mc-alliances-container__items--item__desc">
              <div dangerouslySetInnerHTML={{ __html: allied?.description }} />
            </div>
            </div>
           
            {termLink &&
              termsOnModal
              ? <button className='mc-alliances-button' onClick={modalToggler}>
                {termText}
              </button>
              :
             <div className='px-3'>
               <a onClick={(e)=> dataLayerAllied(e,allied)} className='btn btn-primary fill' href={allied?.subDescription === "edv" ? allied?.link : `/assets/images/alianzas/pdf/${allied?.link_file}`} target="_blank" rel="noopener noreferrer">
                {
                  textBTN ? textBTN : <>Conoce más</>
                }
                
              </a>
             </div>
            }
          </div>
        </>
      }
      {type === 2 &&
        <>
          <div className="mc-alliances-container__items--item__image">
            <span style={{ "backgroundImage": `url(/assets/images/${allied.image})` }}>
              <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
            </span>
          </div>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
            <div className="mc-alliances-container__items--item__title">{allied.name}</div>
          </div>
          <div className="mc-alliances-container__items--item__desc">
            <div dangerouslySetInnerHTML={{ __html: allied.description }} />
          </div>
          {termLink &&
            <a href={termLink} target='_blank' rel="noreferrer">
              {termText}
            </a>
          }
        </>
      }
      {type === 3 &&
        <>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__image">
              <span style={{ "backgroundImage": `url(/assets/images/${allied.image})` }}>
                <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
              </span>
            </div>
            <div className="mc-alliances-container__items--item__labels">
              <div className="mc-alliances-container__items--item__title">{allied.name}</div>
              <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
            </div>
          </div>
          <div className="mc-alliances-container__items--item__desc">
            <div dangerouslySetInnerHTML={{ __html: allied.description }} />
          </div>
          {termLink &&
            <a href={termLink} target='_blank' rel="noreferrer">
              {termText}
            </a>
          }
        </>
      }
      {type === 4 &&
        <>
          <div className="mc-alliances-container__items--item__info">
            <div className="mc-alliances-container__items--item__image">
              <span style={{ "backgroundImage": `url(/assets/images/${allied.image})` }}>
                <img src="/assets/images/dummy.png" alt="Dummy" className='dummy' />
              </span>
            </div>
            <div className="mc-alliances-container__items--item__labels">
              <div className="mc-alliances-container__items--item__title">{allied.name}</div>
              <div className="mc-alliances-container__items--item__rule">{allied.rule}</div>
              <div className="mc-alliances-container__items--item__desc">
                <div dangerouslySetInnerHTML={{ __html: allied.description }} />
              </div>
              {termLink &&
                <a href={termLink} target='_blank' rel="noreferrer">
                  {termText}
                </a>
              }
            </div>
          </div>
        </>
      }

      <CustomModal
        title="Términos y condiciones"
        desc={termsData}
        cta="Aceptar"
        icon={false}
        show={showOnModal} setShowModal={setShowOnModal}
        termsOnModal={true}
      />
    </div>
  )
}

export default AlliedBox